import React from 'react';
import { Field } from 'formik'
import Button from './Button';
import { useDispatch, useSelector } from 'react-redux';
import { productInc, productDec } from "../redux/app/cart/actions";

export function Quantity({ val = 1, min = 1, max = 999, setFieldValue, id = null }) {
  const name = !id ? 'quantity' : `quantity[${id}]`

  const inc = () => {
    if (val >= max) return;
    setFieldValue(name, val + 1)
  }

  const dec = () => {
    if (val <= min) return;
    setFieldValue(name, val - 1);
  }

  return (
    <div className={`quantity`}>
      <Button
        onClick={dec}
        title='-'
      />
      <Field type="number" name={name} />
      <Button
        onClick={inc}
        title='+'
      />
    </div>
  );
}

export function Quantityx({ val, id, min = 1, max = 999 }) {
  const dispatch = useDispatch();

  const inc = () => {
    console.log(val);
    if (val >= max) return;
    dispatch(productInc(id, 1));
  }

  const dec = () => {
    if (val <= min) return;
    dispatch(productDec(id, 1));
  }

  const handleChange = (e) => {
    if (val >= max || val <= min) return;
  }

  return (
    <div className={`quantity`}>
      <Button
        onClick={dec}
        title='-'
      />
      <input
        type="number"
        value={val}
        onChange={handleChange}
        name={'quantity'}
      />
      <Button
        onClick={inc}
        title='+'
      />
    </div>
  );
}