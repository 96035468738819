import React from 'react'
import Button from './Button';

export default function ConfirmModal({ title='', onClose, onRemove }) {
  return (
    <div className='custom-ui'>
      <p>{ title || 'A jeni i sigurtë që doni ta hiqni këtë produkt ?' }</p>
      <div className='actions'>
        <Button classes='green border-radius-4' title='PO, vazhdo' onClick={() => {
          onRemove();
          setTimeout(onClose, 300);
        }} />
        <Button classes='outline blue' title='JO, kthehu' onClick={ onClose } />
      </div>
    </div>
  )
}