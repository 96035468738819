import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'

export default function Transport() {
  return (
    <>
      <Breadcrumbs active_page="Transporti" crumbs={[]} />
      <div className='container'>
        <h3 className='page-title'>Transporti</h3>
        <div className='terms-container privacy-container'>
          <div>
            <ul>
              <li>
                <p>
                  Për çdo porosi të bërë me vlerë deri në €15.00 transporti që duhet të 
                  paguani është €2.00, ndërsa, për çdo porosi të bërë mbi €15.00 
                  transporti është falas.
                </p>
              </li>
              <li><p>Transporti vlen vetëm brenda territorit të Republikës së Kosovës.</p></li>
              <li>
                <p>
                  Ju mund ta gjormoni porosine tuaj me anë të faqes së Buton. Ku për 
                  çdo hap të porosisë do të njoftoheni përmes email-it, ndërsa për të 
                  parë statusin aktual të porosisë suaj, mund të klikoni në profilin juaj te 
                  opsioni "Porositë".
                </p>
              </li>
              <li>
                <p>
                  Porosia hyn në proces vetëm pas konfirmimit nga stafi ynë dhe pas 
                  kësaj fillon llogaritja në bazë të orëve dhe ditëve të punës.
                </p>
              </li>
              <li>
                <p>
                  Për të gjitha porositë vlera e të cilave kalon €15.00 ne kemi ofruar 
                  dërgesë plotësisht falas në adresën e dëshiruar, në territorin e gjithë 
                  Kosovës.
                </p>
              </li>
              <li>
                <p>
                  Koha e pritshme e dorëzimit llogaritet për secilin produkt individualisht,
                  në varësi të sasive të disponueshme dhe kërkesës së produktit 
                  specifik. Zakonisht dorëzimi bëhet mbrenda një ose dy ditë pune. Në 
                  rastin e porositjes së disa produkteve me data të ndryshme të 
                  dorëzimit, data e pritshme përcaktohet nga data më e gjatë. Dorëzimi 
                  bëhet ekskluzivisht në ditë pune.
                </p>
              </li>
              <li>
                <p>
                  Për porosi deri në orën 14:00 ka mundësi dërgimi në të njejtën ditë në 
                  territorin e qytetit të Prishtines.
                </p>
              </li>
              <li>
                <p>
                  Marrësi i dërgesës është i detyruar të vërtetojë tërësinë dhe 
                  integritetin fizik të produkteve të porositura gjatë marrjes së tyre. 
                  Ankesat e mëvonshme për këtë çështje nuk do të pranohen.
                </p>
              </li>
              <li>
                <p>
                  Shërbimi i shpërndarjes së paketave merr përgjegjësinë për çdo 
                  dëmtim fizik të produktit që ndodh gjatë transportit dhe konsumatorit i 
                  dorëzohet një produkt krejt i ri.
                </p>
              </li>
              <li>
                <p>
                  Pranuesit i rekomandohet të mos marrë produkte për të cilat nuk ka 
                  marrë faturë të vërtetuar siç duhet dhe kartë garancie.
                </p>
              </li>
              <li>
                <p>
                  Rekomandohet ruajtja e paketimit origjinal të produkteve të blera dhe 
                  përdorimi i tij në çdo lloj transporti, si dhe në rast ankese të 
                  mundshme.
                </p>
              </li>
              <li>
                <p>
                  Në rast se pranuesi i dërgesës nuk është në adresën e specifikuar në 
                  momentin e dorëzimit, Postieret e shpërndarjes do të tentojnë 
                  dorëzimin maksimumi dy herë të tjera: një herë gjatë së njëjtës ditë 
                  dhe një herë gjatë ditës së ardhshme. Nëse, pas kësaj, dorëzimi është i
                  pasuksesshëm, dërgesa i kthehet dërguesit.
                </p>
              </li>
              <li>
                <p>
                  Dorëzimi bëhet në hyrje të pallatit të banimit. Nëse bëhet fjalë për 
                  objekt banimi, postierët janë të detyruar të dorëzojnë paketën në hyrje
                  të pallatit (jo në hyrje të banesës).
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}