import React, { useState, useEffect } from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import { Link } from 'react-router-dom'
import Button from '../components/Button'
import { ErrorMessage } from '../components/Custom'
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login } from "../redux/app/auth/actions";

export default function Login() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { isAuthenticated, isLoading } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);

  const LoginValidation = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate(state?.from ? state?.from : "/");
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = (values) => {
    dispatch(login(values.email, values.password));
  };

  return (
    <>
      <Breadcrumbs active_page="Llogaria ime" crumbs={[{ link: '/', title: 'Home' }]} />

      <div className='account-forms'>
        <Formik
          initialValues={{
            email: state?.email || "",
            password: "",
          }}
          validationSchema={LoginValidation}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <h4 className='title'>Llogaria ime</h4>
              <div className='form-group'>
                <label htmlFor='email'>Email*</label>
                <Field type="text" id="email" name="email" placeholder="Shkruaj Emailin" />
                {errors.email && touched.email && (
                  <ErrorMessage message={errors.email} />
                )}
              </div>
              <div className='form-group'>
                <label htmlFor='password'>Fjalekalimi*</label>
                <Field type={showPassword ? 'text' : 'password'} id="password" name="password" placeholder="Shkruaj Fjalëkalimin" />
                {errors.password && touched.password && (
                  <ErrorMessage message={errors.password} />
                )}
                <Button
                  classes='align-right'
                  onClick={() => setShowPassword(!showPassword)}
                  icon={
                    !showPassword ?
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16.649 12.961">
                        <path d="M16.136,7.346A9.184,9.184,0,0,0,8.32,2.655,9.184,9.184,0,0,0,.5,7.346a3.4,3.4,0,0,0,0,3.58A9.184,9.184,0,0,0,8.32,15.616a9.184,9.184,0,0,0,7.816-4.691A3.4,3.4,0,0,0,16.136,7.346ZM14.954,10.2a7.846,7.846,0,0,1-6.634,4.03A7.846,7.846,0,0,1,1.687,10.2a2.024,2.024,0,0,1,0-2.128A7.846,7.846,0,0,1,8.32,4.042a7.84,7.84,0,0,1,6.634,4.03A2.024,2.024,0,0,1,14.954,10.2Z" transform="translate(0.004 -2.655)" fill='#888' />
                        <path d="M10.467,7a3.467,3.467,0,1,0,3.467,3.467A3.467,3.467,0,0,0,10.467,7Zm0,5.548a2.08,2.08,0,1,1,2.08-2.08A2.08,2.08,0,0,1,10.467,12.548Z" transform="translate(-2.143 -3.987)" fill='#888' />
                      </svg> :
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
                        <path id="fi-rr-eye-crossed" d="M15.507,6.743A10.755,10.755,0,0,0,13.26,4.077l1.866-1.91a.693.693,0,0,0,0-.964.656.656,0,0,0-.942,0l-2.029,2.08A7.893,7.893,0,0,0,8,2.13,8.813,8.813,0,0,0,.484,6.743a3.414,3.414,0,0,0,0,3.521,10.755,10.755,0,0,0,2.246,2.666L.865,14.839a.693.693,0,0,0,0,.964.656.656,0,0,0,.942,0l2.034-2.082A7.894,7.894,0,0,0,8,14.876a8.813,8.813,0,0,0,7.511-4.613A3.414,3.414,0,0,0,15.507,6.743ZM1.62,9.549a2.03,2.03,0,0,1,0-2.092A7.533,7.533,0,0,1,8,3.494a6.607,6.607,0,0,1,3.176.795L9.83,5.661a3.27,3.27,0,0,0-4.192.429,3.464,3.464,0,0,0-.419,4.29L3.679,11.956A9.3,9.3,0,0,1,1.62,9.549ZM9.995,8.5a2.023,2.023,0,0,1-2,2.046,1.93,1.93,0,0,1-.856-.2L9.795,7.627A2.051,2.051,0,0,1,9.995,8.5Zm-4,0A2.023,2.023,0,0,1,8,6.457a1.93,1.93,0,0,1,.856.2L6.2,9.379A2.051,2.051,0,0,1,6,8.5Zm8.375,1.046A7.533,7.533,0,0,1,8,13.512a6.607,6.607,0,0,1-3.176-.795l1.342-1.373a3.27,3.27,0,0,0,4.192-.429,3.464,3.464,0,0,0,.419-4.29L12.312,5.05a9.3,9.3,0,0,1,2.059,2.407,2.03,2.03,0,0,1,0,2.092Z" transform="translate(0.004 -1.003)" fill="#888" />
                      </svg>
                  }
                />
              </div>

              <div className='d-flex flex-column align-center'>
                <Button type='submit' title='Kyqu' classes='green submit' loading={isLoading} />
                <Link to='/reset'>Keni harruar fjalekalimin?</Link>
                <Link to='/register'>Nuk jeni te regjistruar? <span> Krijo llogari</span></Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}