import React, { useEffect } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import api from "../utils/axios";
import { useState } from "react";
import { useParams } from "react-router-dom";

export default function Terms() {
  const [content, setContent] = useState([]);
  const {id} = useParams();

  useEffect(() => {
    async function handleFetchPageContent() {
      try {
        const response = await api.get(`/2.0/pages/${id}`);
        setContent(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    handleFetchPageContent();
  }, [id]);

  return (
    <>
      <Breadcrumbs active_page={`${content.page}`} crumbs={[]} />
      <div className="container">
        <h3 className="page-title">{content.page}</h3>
        <div className="terms-container">
          <h3> {content.page}</h3>
          <div dangerouslySetInnerHTML={{ __html: content.description }}></div>
        </div>
      </div>
    </>
  );
}
