import { put, takeEvery } from 'redux-saga/effects';
import { login_success, failure, logout } from './actions'
import { setMessage } from '../custom/actions'
import { addToDb } from '../cart/actions'
import { getUser } from '../profile/actions'
import api from '../../../utils/axios'

import { 
  AUTHENTICATION_INIT, 
  AUTHENTICATION_SUCCEED,
  AUTHENTICATION_LOGIN, 
  AUTHENTICATION_LOGOUT
} from './types'

// watcher
export function* authenticateWatcher() {
  yield takeEvery(AUTHENTICATION_INIT, INIT);
  yield takeEvery(AUTHENTICATION_LOGIN, LOGIN);
  yield takeEvery(AUTHENTICATION_SUCCEED, LOGIN_SUCCEED);
  yield takeEvery(AUTHENTICATION_LOGOUT, LOGOUT);
}

// worker
export function* LOGIN({ email, password }) {
  try {
    const data = (yield api.post('/auth_tokens', { email, password })).data;
    if(data?.token){
      yield put(login_success(data.token,
        {
          id: data.user_data.user_id,
          email: data.user_data.email,
          firstname: data.user_data.firstname,
          lastname: data.user_data.lastname,
          exp: data.exp * 1000 
        },
        true
      ));
      yield put(getUser());
    }
  } catch (e){
    yield put(setMessage('warning', 'Email or password incorrect.'))
    yield put(failure())
  }
}

export function* LOGIN_SUCCEED(data) {
  if (data.user.exp < Date.now()) {
    yield put(logout());
  } else {
    if(data.alert){
      yield localStorage.setItem('token', data.token);
      yield localStorage.setItem('auth.user', JSON.stringify(data.user));
      yield put(setMessage('success', 'U kycet me suksese.'))
      yield put(addToDb())
    }
  }
}

export function* INIT() {
  const token = yield localStorage.getItem('token');
  const user = yield localStorage.getItem('auth.user');

  const _user = JSON.parse(user);
  if (_user && token) {
    yield put(login_success(token, _user));
    yield put(getUser());
  } 
}

export function* LOGOUT({ msg }) {
  try {
    yield localStorage.removeItem('token');
    yield localStorage.removeItem('auth.user');
    if (msg) yield put(setMessage('info', 'U ckycet me suksese.'))
  } catch (error) { 
    console.log(error);
  }
}
