import { combineReducers } from 'redux';
import customReducer from "../app/custom/reducer";
import authentication from "../app/auth/reducer";
import ctReducer from "../app/categories/reducer";
import featuredReducer from "../app/products/featured/reducer";
import productsReducer from "../app/products/all/reducer";
import productReducer from "../app/products/single/reducer";
import trendsReducer from "../app/products/trends/reducer";
import cartReducer from "../app/cart/reducer";
import wishlistReducer from "../app/wishlist/reducer";
import compareReducer from "../app/compare/reducer";
import registerReducer from "../app/register/reducer";
import searchInputReducer from "../app/search-input/reducer";
import searchReducer from "../app/search/reducer";
import profileReducer from "../app/profile/reducer";
import ordersReducer from "../app/orders/reducer";
import bannersReducer from "../app/banners/reducer";
import dealsReducer from "../app/deals/reducer";
import resetReducer from "../app/reset/reducer";
import shippingReducer from '../app/shippings/reducer'

const rootReducer = combineReducers({
  auth: authentication,
  custom: customReducer,
  categories: ctReducer,
  featured: featuredReducer,
  trends: trendsReducer,
  products: productsReducer,
  cart: cartReducer,
  wishlist: wishlistReducer,
  product: productReducer,
  compare: compareReducer,
  register: registerReducer,
  searchInput: searchInputReducer,
  search: searchReducer,
  profile: profileReducer,
  orders: ordersReducer,
  banners: bannersReducer,
  deals: dealsReducer,
  reset: resetReducer,
  shippings: shippingReducer,
});

export default rootReducer;
