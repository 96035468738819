import React from 'react'
import Button from './Button'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { logout } from "../redux/app/auth/actions";
import { clearCart } from "../redux/app/cart/actions";

export default function MobileMenu({ mobileMenu, setMobileMenu }) {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(state => state.auth)
  const { categories } = useSelector(state => state.categories) 

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearCart());
    localStorage.removeItem('cart')
    setMobileMenu(false);
  }

  return (
    <div className={`mobile-side-menu ${ mobileMenu && 'active' }`} onClick={ () => setMobileMenu(false) }>
      <div className='content' onClick={ e => e.stopPropagation() }>
        <div className='d-flex align-center justify-between heading'>
          <h5>Menu</h5>
          <Button
            onClick={ () => setMobileMenu(false) }
            icon={
              <svg id="fi-rr-cross-circle" xmlns="http://www.w3.org/2000/svg" width="25.421" height="25.421" viewBox="0 0 25.421 25.421">
                <path id="Path_420" data-name="Path 420" d="M16.491,8.017a1.059,1.059,0,0,0-1.5,0l-2.739,2.739L9.515,8.017a1.059,1.059,0,1,0-1.5,1.5l2.739,2.739L8.017,14.993a1.059,1.059,0,1,0,1.5,1.5l2.739-2.739,2.739,2.739a1.059,1.059,0,1,0,1.5-1.5l-2.739-2.739,2.739-2.739A1.059,1.059,0,0,0,16.491,8.017Z" transform="translate(0.456 0.456)" fill="#fff"/>
                <path id="Path_421" data-name="Path 421" d="M12.711,0A12.711,12.711,0,1,0,25.421,12.711,12.711,12.711,0,0,0,12.711,0Zm0,23.3A10.592,10.592,0,1,1,23.3,12.711,10.592,10.592,0,0,1,12.711,23.3Z" transform="translate(0 0)" fill="#fff"/>
              </svg>
            }
          />
        </div>
        <ul className='menu'>
          { categories && [...categories.keys()].map(item => {
            const category = categories.get(item)
            return (
              <li key={ category.category_id }>
                <Link to={`/search?c=${category.category_id}`} onClick={ () => setMobileMenu(false) }>
                  <img src={ category.main_pair?.icon.image_path } alt={ category.category } style={{width:"25px"}} />
                  <span>{ category.category }</span>
                </Link>
              </li>
            )
          }) }
          <li className='seperator'>
            <Link to="/wishlist" onClick={ () => setMobileMenu(false) }>
              <img src='/assets/images/categories/wishlist-icon.svg' alt='Lista e deshirave' />
              <span>Lista e deshirave</span>
            </Link>
          </li>
          <li>
            <Link to={ isAuthenticated ? `/profile` : `/login` } onClick={ () => setMobileMenu(false) }>
              <img src='/assets/images/categories/profile.svg' alt='Profili' />
              <span>Profili</span>
            </Link>
          </li>

          { isAuthenticated && (
            <>
              <li>
                <Link to={`/orders`} className='item' onClick={ () => setMobileMenu(false) }>
                  <img src='/assets/images/categories/orders-history.svg' alt='Historia e porosive' />
                  <span>Historia e porosive</span>
                </Link>
              </li>
              <li>
                <Link to='' onClick={ handleLogout }>
                  <img src='/assets/images/categories/logout.svg' alt="Ç’kyqu" />
                  <span>Ç’kyqu</span>
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  )
}