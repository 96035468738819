import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './redux/store'
import { init } from "./redux/app/auth/actions";
import { clearCartDB, couponInit, getCart } from "./redux/app/cart/actions";
import { getWishlist } from "./redux/app/wishlist/actions";
import { getCategories } from "./redux/app/categories/actions";

store.dispatch(init());
store.dispatch(getCategories(null));
store.dispatch(getCart());
store.dispatch(getWishlist());
store.dispatch(couponInit());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
