import React from 'react'
import Button from '../Button'
import { ErrorMessage } from '../Custom'
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { requestResetPassword } from '../../redux/app/profile/actions';

export default function ResetIndex() {
  const dispatch = useDispatch();
  const { isSubmitting } = useSelector(state => state.profile);

  const ResetValidation = Yup.object().shape({
    email: Yup.string().email().required("Email is required")
  });

  const handleSubmit = (values) => {
    dispatch(requestResetPassword(values.email));
  };

  return (
    <>
      <div className='account-forms'>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={ResetValidation}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <h4 className='title'>Keni harruar fjalekalimin tuaj</h4>
              <p className='sub-title'>
                Keni humbur fjalëkalimin tuaj? Ju lutemi shkruani emrin e përdoruesit ose adresën tuaj të emailit.
                Ju do të merrni një lidhje për të krijuar një fjalëkalim të ri përmes emailit.
              </p>
              <div className='form-group'>
                <label htmlFor='email'>Email*</label>
                <Field type="text" id="email" name="email" placeholder="Shkruaj Emailin" />
                {errors.email && touched.email && (
                  <ErrorMessage message={errors.email} />
                )}
              </div>

              <div className='d-flex flex-column align-center'>
                <Button type='submit' title='Dergo' classes='green submit' loading={isSubmitting} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}