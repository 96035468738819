import React, { Fragment, useEffect } from "react";
import "../Orders/invoice.module.css";
import { fixPrice } from "../../utils/number";
import InnerHTML from "dangerously-set-html-content";
import { getShippings } from '../../redux/app/shippings/actions'
import { useSelector, useDispatch } from "react-redux";
export default function ProductPdf({ reff, product, features }) {
  const dispatch = useDispatch();
  const { shippings } = useSelector((state) => state.shippings)
  const html = `${product.full_description}`;
  useEffect(() => { dispatch(getShippings()) }, [dispatch])
  return (
    <div ref={reff} className="print-font-family product-pdf">
      <div className="pdf-header">
        <a href="https://butonks.com/">
          <img src="/assets/images/logo.svg" alt="Logo" />
        </a>
      </div>
      <div className="pdf-container">
        <div className="pdf-info">
          <div className="pdf-image">
            <img
              src={product?.main_pair.detailed?.image_path}
              alt={product.product}
            />
          </div>
          <div className="pdf-desc">
            <div className="">
              <h3 className="pdf-title">{product.product}</h3>
              <p>
                <b>Kodi i produktit: </b>
                {product.product_code}
              </p>
              {product.out_of_stock_actions === "S" &&
                product.amount == 0 ? (
                <></>
              ) : (
                <>
                  {product.out_of_stock_actions === "B" ? (
                    <p>
                      <span>Transporti</span>: {shippings && shippings[1]?.delivery_time}
                    </p>
                  ) : (
                    <p>
                      <span>Transporti</span>: {shippings && shippings[0]?.delivery_time}
                    </p>
                  )}
                </>
              )}
            </div>
            <div className="pdf-price">
              <span>Çmimi: </span>
              <h2 className="pdf-list-price">
                {product.price !== "0.000000"
                  ? fixPrice(product.price) + " \u20AC"
                  : "Contact us for price"}
              </h2>
              {product.list_discount_prc > 0 && (
                <h3 className="pdf-last-price">
                  {fixPrice(product.list_price)} &euro;
                </h3>
              )}
            </div>
          </div>
        </div>

        <div style={{ marginTop: '0.5%' }}>
          <h2>Detajet</h2>

          <InnerHTML
            style={{
              fontSize: '8px', textAlign: 'left !important'
            }}
            html={html}
          />
        </div>
        <div style={{ marginTop: '20%', textAlign: 'left' }}>
          <h2>Përshkrimi i Produktit</h2>
          {features &&
            features.map((item, index) => (
              <div key={index} style={{ textAlign: 'left' }}>
                {index > 0 && (
                  <div style={{ textAlign: 'left' }} key={item.feature_id}>
                    <p style={{ textAlign: 'left' }}>{item.description}</p>
                  </div>
                )}
                {item.subs.map((item2) => (
                  <div style={{ disply: 'flex', flexDirection: 'row', textAlign: 'left' }} key={item2.feature_id}>
                    <p style={{ textAlign: 'left' }}>{item2.description}:</p>
                    <p style={{ textAlign: 'left' }}>
                      {item2.variant_id != 0
                        ? item2.variants[item2.variant_id].variant
                        : item2.value}
                    </p>
                  </div>
                ))}
              </div>
            ))}
          {product?.features_description &&
            Object.keys(product.features_description).map((item, index) => (
              <div key={index} style={{ textAlign: 'left' }}>
                <div style={{ textAlign: 'left' }}>
                  <p style={{ textAlign: 'left' }}>{item}</p>
                </div>
                {Object.keys(product.features_description[item]).map(
                  (item2, index2) => (
                    <div key={index2} style={{ textAlign: 'left' }}>
                      <p style={{ textAlign: 'left' }}>{item2}:</p>
                      <p style={{ textAlign: 'left' }}>
                        {product.features_description[item][item2]}
                      </p>
                    </div>
                  )
                )}
              </div>
            ))}
        </div>

      </div>
      <div className="pdf-footer" style={{ position: "static", marginTop: 50 }}>
        <p>
          Specifikat jan të bazuara në të dhënat e prodhuesit. <br />
          Buton nuk mban përgjegjësi nëse në specifikat e detajuara ka ndonjë
          gabim. <br />
          Për më tepër ju lutem të vizitoni webfaqen zyrtare të produktit.{" "}
          <br />
        </p>
        <div className="pdf-copyright">
          <p>
            &copy; BUTON Electronics | Rr. Elez Berisha, Zona Industriale |
            12000 Fushë Kosovë | +383 43 99 77 00
          </p>
        </div>
      </div>
    </div>
  );
}
