import React from 'react'

export default function Button({type = "button", title = '', onClick, icon = '', loading = false, disabled = false, classes = ''}) {
  return (
    <button 
      type={ type }
      onClick={ onClick }
      disabled={ disabled }
      className={`btn ${ classes } ${ loading && 'loader' }` }
    >
      { icon }
      { title }
    </button>
  )
}