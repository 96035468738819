import { put, takeEvery } from "redux-saga/effects";
import { setFeatured } from "./actions";
import api from "../../../../utils/axios";

import { GET_FEATURED } from "./types";

// watcher
export function* featuredWatcher() {
  yield takeEvery(GET_FEATURED, INIT);
}

// worker
export function* INIT({ productType, perPage }) {
  try {
    let options = {};
    if (productType === "newest") {
      options.sort_by = "timestamp"; // to do add on api
      options.sort_order = "newest";
    } else if (productType === "mostViewd") {
      options.sort_by = "prodict_id";
      options.sort_order = "desc";
    }

    const data = (yield api.get("/categories/2/products", {
      params: {
        status: "A",
        amount_from: 1,
        items_per_page: perPage,
        ...options,
      },
    })).data;

    // console.log(data);

    yield put(setFeatured(data.products));
  } catch (e) {
    console.log(e);
  }
}
