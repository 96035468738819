import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'

export default function Privacy() {
  return (
    <>
      <Breadcrumbs active_page="Privatësia dhe Kushtet" crumbs={ [] } />
      <div className='container'>
        <h3 className='page-title'>Privatësia dhe Kushtet</h3>
        <div className='terms-container privacy-container'>
          <div>
            <h3>Privatësia dhe Kushtet</h3>
            <p>
              Kur ju përdorni shërbimet tona ju ndani disa informata me ne. Privatësia juaj është
              jashtëzakonisht e rëndësishme për ne. Ne përgjigje të rregullores së re për mbrojtjen e të
              dhënave (GDPR), që ka hyrë në fuqi në Bashkimin Evropian më 25 Maj 2018, ne kemi
              përditesuar Kushtet e Shërbimit dhe Politikat e Privatësisë. Megjithëse kjo rregullore kërkon
              zbatim vetëm për qytetarët në Bashkimin Evropian, ne kemi filluar t’i aplikojmë këtë rregullore
              edhe në Kosovë.
            </p>
            <p>
              Çdo përdorues që kryen regjistrim dhe/apo blerje Online, deklaron se është i njohur dhe
              pajtohet me Politika e Privatësisë sonë
            </p>
          </div>
          <div>
            <h3>Çfarë informacioni mbledhim?</h3>
            <p>Informacionet që ne kemi nevojë për regjistrim dhe / ose për ato:</p>
            <ul type="circle" style={{ listStyleType: 'square'}}>
              <li> - Emri dhe Mbiemri</li>
              <li> - E-mail adresa</li>
              <li> - Adresa e Dërgesës apo Adresës së faturimit</li>
              <li> - Numri I Telefonit</li>
            </ul>
            <p>&nbsp;</p>
            <p>
              Të dhënat që ne mbledhim dhe mbajmë, nuk do të publikohen, shiten ose dorëzohen tek një
              palë e tretë, përveç nëse autoritetet kompetente në mënyrën të përcaktuar nga legjislacioni i
              Republikës së Kosovës I kërkojnë.
            </p>
            <p>
              Buton Electronics rezervon të drejtën për të përdorur IP adresën dhe të dhëna të tjera të
              përdoruesit për të zbuluar identitetin e tyre në rastin e zbatimit të ligjit dhe procedurave ligjore.
            </p>
            <p>
              Me përjashtim të të dhënave të natyrës financiare, të gjitha informatat që ne mbledhim nga faqja
              e internetit janë ekskluzivisht për përdorimin tonë (marrëdhënie me klientët, me qëllim të
              përcjelljes, etj). Dhe ato nuk do ju kalohen të tjerëve për asnjë arsye. <b>Ne nuk mbledhim ndonjë
              informacion të natyres financiare, si numri i kartës.</b>
            </p>
            <p>
              Të dhënat mbi kartën e kreditit dhe llogari bankare do të mbahen dhe përdoren vetëm nga Banka për
              pagesën e produkteve / urdhrave për kryerjen e transaksionit. Transaksioni në krye të produktit është i I
              përpunuar përmes kartës së kreditit dhe pagesa bëhet përmes portalit të Bankës.
            </p>
            <p>
              <i>Të miturit nuk mund të japin të dhëna personale pa leje nga prindërit ose kujdestarët e tyre dhe ne nuk do
              angazhohemi pa vetëdijen e tyre për të mbledhë apo të përdorë të dhënat nga të miturit.</i>
            </p>
            <p>
              <i>Nëse shfaqet ndonjë link tjetër qe ju lidhë me WEB site tjera, ne nuk marrim asnjë përgjegjësi në lidhje
              me mbrojtjen e të dhënave personale nga ato faqet të internetit.</i>
            </p>
            <p>
              Kjo politikë e privatësisë hyn në fuqi që Korrik 2022. <br />
              Ndryshimet në politikën e privatësisë do të publikohen në webfaqen www.butonks.com 10 ditë para se të hyjnë në fuqi.
            </p>
          </div>
          <div>
            <h3>COOKIES</h3>
            <p>
              Cookies është një fajll standard që vendoset në kompjuterin apo pajisjen tuaj dhe shërben për
              të lehtësuar ndërveprimin ndërmjet përdoruesit dhe web faqes. Ato lejojnë që web faqet të
              ruajnë preferencat dhe aktivitetet e shfrytëzuesit, në këtë mënyrë kursen kohë dhe kontribuon
              në shfletimin efikas të internetit.
            </p>
            <p>
              Njëra prej shumë arsyeve për të përdorur Cookies është aftësia e tyre për të ruajtur
              informacione në faqen e internetit, për preferenca të përdoruesit (p.sh. përzgjedhja e gjuhës,
              numri i klikimeve, madhësia e shkronjave, lloji i fontit, etj). Përveç kësaj Cookies gjithashtu
              lehtësojnë implementimin e shërbimeve të internetit (regjistrimi në web faqe, shporta e blerjes
              online, abonimet, shikimi i produkteve etj) gjithashtu edhe mbledhjen e informatave mbi zakonet
              e përdoruesve të internetit, (numri i vizitave, përmbajtjet interesante për përdoruesit etj.) Të
              gjitha këto opsione ndihmojnë për të përmirësuar përvojën e përdoruesit dhe vlerësojnë
              efikasitetin e web faqes.
            </p>
            <p>
              Për hir të Transparencës, ne kemi bërë një listë të të gjitha Cookies që ne përdorim dhe qëllimet
              e tyre. Ju lutemi të mbani në mend se me anë të përdorimit të mëtejshëm të kësaj faqe ju jeni
              duke rënë dakord me përdorimin e Cookies.
            </p>
          </div>

          <div>
            <h3>Termat dhe Kushtet</h3>
            <p>
              BUTON do të ju përgjigjet kërkesave tuaja duke siguruar qasje në këtë webfaqe 24 orë në ditë.
            </p>
            <p>
              Në rast të ndërprerjes së internetit për arsyeje teknike apo si rezultat i ndonjë situate të shkaktuar nga
              faktorë të jashtëm që janë përtej mundësisë së kontrollit nga Buton Electronics, atëherë Buton
              distancohet nga kjo përgjegjësi dhe nuk garanton qasje të përhershme.
            </p>
            <p>
              Të gjitha të dhënat dhe informacionet e publikuara në webfaqen e Buton Electronics janë përmbajtje e
              autorizuar nga brendet me të cilat bashkëpunon dhe vetëm Buton Electronics rezervon të drejtën për
              të ndryshuar apo përditesuar ato.
            </p>
            <p>
              Përderisa &quot;internet&quot; është një medium me përditësim të vazhdueshëm, përveç nëse parashihet ndryshe
              në rregulloret e aplikueshme në Kosovë, Buton Electronics merr përgjegjësi për saktësinë dhe
              besueshmërinë e informacionit dhe përmbajtjes së publikuar.
            </p>
            <p>
              Emri &quot;<b>Buton</b> dhe/apo <b>Buton Electronics</b>&quot; është markë tregtare e P.C. COMP sh.p.k me Numër Unik:
              810546856 me adresë Rr. Ali Vitia Nr. 225, Prishtinë, Republika e Kosovës dhe rezervon të drejtën
              ekskluzive të çfarëdo të dhëne që lidhet me të dhe përdorimi pa pëlqimin paraprak dhe me shkrim
              ndalohet rreptësisht . P.C. COMP shpk rezervon të gjitha të drejtat që rrjedhin nga marka tregtare
              &quot;<b>Buton</b> dhe apo Buton <b>Electronics</b>&quot;.
            </p>
            <p>
              Përdorimi dhe shpërndarja e informacionit, të dhënave, përmbajtjes, imazheve nga faqja e Buton Electronics është e ndaluar.
            </p>
            <p>
              Po ashtu Buton Electronics ju njofton që kjo faqe përdor fotografi me tekst të quajtur &quot;cookies &quot;. Pa këto
              Web faqja nuk do të funksionojë si duhet dhe shumë nga opsionet nuk do jenë në gjendje të shihen.
            </p>
            <p>
              Më shumë informacion në lidhje me atë çfarë janë &quot;cookies&quot; mund ti gjeni këtu: <br/>
              <a href="https://en.wikipedia.org/wiki/HTTP_cookie" style={{ color: 'red' }}>https://en.wikipedia.org/wiki/HTTP_cookie</a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}