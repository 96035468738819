import React, { useEffect } from 'react'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { fixPrice } from '../../utils/number';
import { SmallProduct } from '../Product';
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import Button from '../Button';
import { ErrorMessage } from '../Custom'
import { createOrder } from '../../redux/app/orders/actions';

export default function OrderSummary({ step, setStep }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isBeingCreated, created, order_id, notes } = useSelector(state => state.orders);
  const { cartProducts, subtotal, couponDiscount, total, length } = useSelector(state => state.cart);

  const BuySchema = Yup.object().shape({
    payment_id: Yup.string().required("Zgjedhni menyren e pageses."),
    terms: Yup.boolean().required().oneOf([true], "Ju duhet te pajtoheni me termet dhe kushtet"),
  });

  const handleSubmit = (values) => {
    dispatch(createOrder(values))
  }

  useEffect(() => {
    if(order_id && created && !isBeingCreated) navigate('/cart/success');
  }, [isBeingCreated, created, order_id])

  return (
    <>
      <div className='tabs'>
        <span className={ step === 1 ? `active` : ''} onClick={ () => setStep(1)}>Detajet e fatures</span>
        <span className={ step === 2 ? `active` : ''} onClick={ () => setStep(2)}>Permbledhja e porosise</span>
      </div>
      <Formik
        initialValues={{
          payment_id: '',
          terms: false,
          shipping_id: 1,
          notes: notes
        }}
        validationSchema={BuySchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched }) => (
          <Form>
            <h5 className='heading'>Permbledhje</h5>
            <div className={`search-results small-products`}>
              <div className="search-scroll">
                { length > 0 && cartProducts.map(item => (
                  <SmallProduct key={ item.product_id } showQty={true} showPrice={true} item={ item } />
                )) }
              </div>
            </div>
            
            <div className='cart-total'>
              <div className='d-flex justify-between'>
                <p>Nen Totali</p>
                <p>{ fixPrice(subtotal) } &euro;</p>
              </div>
              
              <div className='d-flex justify-between'>
                <p>Transporti</p>
                <p>00.00 &euro;</p>
              </div>

              { couponDiscount > 0 && (
                <div className='d-flex justify-between'>
                  <p>Zbritje kuponi</p>
                  <p>-{ fixPrice(couponDiscount) } &euro;</p>
                </div>
              )}

              <div className='d-flex justify-between'>
                <p>Totali</p>
                <p>{ fixPrice(total) } &euro;</p>
              </div>
            </div>

            <div className='place-order-container'>
              <div className='place-order'>
                <label className="costum-radio">
                  <div className='method-title'>
                    <span>Transfere bankar</span>
                    <span>Paguaj me transfere bankar</span>
                  </div>
                  <Field type="radio" name="payment_id" value="5" />
                  <span className="checkmark"></span>
                </label>    
                <label className="costum-radio">
                  <div className='method-title'>
                    <span>Pagesa me para ne dore</span>
                    <span>KESH</span>
                  </div>
                  <Field type="radio" name="payment_id" value="6" />
                  <span className="checkmark"></span>
                </label>
              </div>
              { errors.payment_id && touched.payment_id && <ErrorMessage message={ errors.payment_id } /> }
              { values.payment_id === '5' && 
                <div className='bank-transfer'>
                  <p className='margin-bottom'>Detajet për pagesë me transfer bankarë:</p>
                  <p><span>Emri i Kompanisë:</span> P.C. COMP shpk</p>
                  <p className='margin-bottom'><span>Adresa:</span> Rr. Ali Vitia Nr. 225, 10000 Prishtinë, Republika e Kosovës</p>
                  <p><span>Llogaritë Bankare:</span></p>
                  <p><span>ProCredit Bank:</span> 1110223830000160</p>
                  <p><span>NLB Bank:</span> 1700200100139562</p>
                  <p><span>Raiffeisen Bank:</span> 1501090002826362</p>
                  <p className='margin-bottom'><span>Banka Ekonomike:</span> 1401000000507198</p>
                  <p>Gjat pagesës me transfer bankarë, ju lutemi të cekni numrin e Faturës në mënyr që porosia juaj të procesohet sa më shpejt.</p>
                </div>
              }
            </div>

            <label className="custom-checkbox">Kam lexuar dhe pajtohem me faqen <Link to='/'>Terms & Conditions</Link>*
              <Field type="checkbox" name="terms"  />
              <span className="checkmark"></span>
            </label>
            { errors.terms && touched.terms && <ErrorMessage message={ errors.terms } /> }
            <Button 
              type='submit' 
              title='Beje porosine' 
              loading={ isBeingCreated }
              classes='green border-radius-4 place-order-btn' 
            />
          </Form>
        )}
      </Formik>
    </>
  )
}