import { put, takeEvery } from "redux-saga/effects";
import { setProducts } from "./actions";
import api from "../../../../utils/axios";

import { GET_PRODUCTS } from "./types";

// watcher
export function* productsWatcher() {
  yield takeEvery(GET_PRODUCTS, INIT);
}

// worker
export function* INIT({ data }) {
  try {


    const response = (yield api.get("/categories/3/products", {
      params: {
        status: "A",
        amount_from: 1,
        sort_by: 'product_id', sort_order: 'newest',
        ...data,
      },
    })).data;

    yield put(setProducts(response.products));
  } catch (e) {
    console.log(e);
  }
}
