import React, { useEffect, useCallback } from 'react'
import SideShoppingBag from './SideShoppingBag';
import SideWishlist from './SideWishlist';

export default function Side({ isVisible, setVisibility }) {
  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setVisibility && setVisibility(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <aside id="cart" className={ isVisible ? 'active-overlay' : null } onClick={ () => setVisibility(false) }>
      <div className='cart-content' onClick={ e => e.stopPropagation() }>
        { isVisible === "shopping" && <SideShoppingBag setVisibility={ setVisibility } /> }
        { isVisible === "wishlist" && <SideWishlist setVisibility={ setVisibility } /> }
      </div>
    </aside>
  )
}