import { put, takeLatest } from "redux-saga/effects";
import { setSearchProducts } from "./actions";
import { GET_SEARCH_PRODUCTS } from "./types";
import api from "../../../utils/axios";

// watcher
export function* searchInputWatcher() {
  yield takeLatest(GET_SEARCH_PRODUCTS, INIT);
}

// worker
export function* INIT({ keyword }) {
  try {
    const { products } = (yield api.get(`/products`, {
      params: {
        pname: "Y",
        pcode_from_q: "Y",
        pshort: "Y",
        pfull: "Y",
        sort_by: "product",
        amount_from: 0,
        status: "A",
        q: keyword,
      },
    })).data;

    yield put(setSearchProducts(products));
  } catch (e) {
    console.log(e);
  }
}
