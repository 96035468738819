import React,{useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import api from '../utils/axios';

export default function Footer() {

  const [pages, setPages] = useState(null);

useEffect(() => {
  async function handleFetchPages() {
    try {
      const response = await api.get(`/2.0/pages`);
      setPages(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  handleFetchPages();
}, []);

  return (
    <footer>
      <div className='container'>
        <div>
          <div className='footer-about'>
            <img src="/assets/images/logo-white-x2.svg" alt="Buton logo" />
            <div className='socials'>
              <a href='https://www.facebook.com/ButonElectronics' target={'_blank'}>
                <img src="/assets/images/icons/facebook.svg" alt="Facebook logo" />
              </a>
              <a href='https://www.instagram.com/butonelectronics/' target={'_blank'}>
                <img src="/assets/images/icons/instagram.svg" alt="Instagram logo" />
              </a>
            </div>
          </div>
          {pages !== null && (
          <>
            <ul>
              {pages.pages.slice(0, 5).map(page => (
                <li key={page.id_path}><Link to={`/page/${page.id_path}`}>{page.page}</Link></li>
              ))}
            </ul>
            <ul>
              {pages.pages.slice(5, 10).map(page => (
                <li key={page.id_path}><Link to={`/page/${page.id_path}`}>{page.page}</Link></li>
              ))}
            </ul>
          </>
        )}
          <ul>
            <li>Kontakt</li>
            <li>+383 43 997 700</li>
            <li>info@butonks.com</li>
            <li>Elez Berisha, 10000, <br /> Prishtinë</li>
          </ul>
        </div>
        <div className='copyright'>
          <p>© Copyright 2022 ButonElectronics. All Right Reserved</p>
        </div>
      </div>
    </footer>
  )
}