import React, { useEffect, useState } from "react";
import Banner from "../components/Home/Banner";
import Featured from "../components/Home/Featured";
import Trio from "../components/Home/Trio";
import Products from "../components/Home/Products";
import Categories from "../components/Home/Categories";
import PreferedModels from "../components/Home/PreferedModels";
// import Marquee from 'react-double-marquee';
import Button from "../components/Button";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { getProducts } from "../redux/app/products/all/actions";
import Banners from "../components/Home/Banners";

function Home() {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const { products, isLoading, page } = useSelector((state) => state.products);
  const dispatch = useDispatch();

  const getMoreProducts = () => {
    dispatch(getProducts({ page: page + 1 }));
  };

  useEffect(() => {
    if (products.length <= 0) dispatch(getProducts({ page }));
  }, [dispatch]);

  console.log(products, "product")
  
  
  return (
    <>
      {!isMobile && <Banner />}

      <Trio />

      {isMobile && <Categories isMobile={isMobile} />}

      <Featured />

      {/* <div className="marquee">
        <Marquee childMargin={0} direction='left' speed={ 0.15 }>
          <span className="txt">Computers & Electronics Store</span>
          <img src="/assets/images/logo-icon.svg" alt="Buton logo" />
          <span className="txt">All your styles are here</span>
        </Marquee>
      </div> */}

      <div className="container">
        <div className="brands">
          <div className="item">
            <img src="/assets/images/brands/dell.svg" alt="Dell" />
          </div>
          <div className="item">
            <img src="/assets/images/brands/asus.svg" alt="Asus" />
          </div>
          <div className="item">
            <img src="/assets/images/brands/apple.svg" alt="Apple" />
          </div>
          <div className="item">
            <img src="/assets/images/brands/razer.svg" alt="Razer" />
          </div>
          <div className="item">
            <img src="/assets/images/brands/acer.svg" alt="Acer" />
          </div>
          <div className="item">
            <img src="/assets/images/brands/logitech.svg" alt="Logitech" />
          </div>
          <div className="item">
            <img src="/assets/images/brands/aorus.svg" alt="Aorus" />
          </div>
          <div className="item">
            <img src="/assets/images/brands/corsair.svg" alt="Corsair" />
          </div>
        </div>
      </div>

      <Products products={products} isLoading={isLoading}>
        <div className="d-flex justify-center">
          <Button
            title="Shiko me shume"
            classes="outline blue see-more"
            onClick={getMoreProducts}
          />
        </div>
      </Products>

      {!isMobile && <Categories isMobile={isMobile} />}

      {/* <Banners /> */}

      <PreferedModels rows={2} heading="Modelet tona te preferuara" />
    </>
  );
}

export default Home;
