import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../Breadcrumbs'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import OrderSummary from './OrderSummary';
import OrderDetails from './OrderDetails';
import { toast } from 'react-toastify';

export default function CartBuy() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const { user } = useSelector(state => state.profile);
  const { length, isLoading } = useSelector(state => state.cart);
  const { order_id, created } = useSelector(state => state.orders);
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if(!isAuthenticated){
      toast.info("Ju duhet te kyceni per te vazhduar tek blerja");
      navigate('/login', { state: { from: `/cart/buy` }});
    }
  }, [])

  useEffect(() => {
    if(length <= 0 && !isLoading && !order_id && !created) navigate('/');
  }, [length, isLoading, order_id, created])

  return (
    <>
      <Breadcrumbs active_page="Detajet e pageses" crumbs={ [ { link: '/shporta', title: 'Shporta' } ] } />
      <div className='container cart-container'>
        <h3 className='page-title'>Detajet e pageses</h3>
        <div className='cart-details'>
          <div className='buy-form'>
            { step === 1 && user && <OrderDetails step={step} setStep={ setStep } /> }
            { step === 2 && <OrderSummary step={step} setStep={ setStep } /> }
          </div>
        </div>
      </div>
    </>
  )
}