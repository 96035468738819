import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";

export default function CostumerCare() {
  return (
    <>
      <Breadcrumbs active_page="Kujdesi ndaj klienteve" crumbs={[]} />
      <div className="container">
        <h3 className="page-title">Kujdesi ndaj klienteve</h3>
        <div className="terms-container privacy-container">
          <div>
            <h3>Kujdesi ndaj klientëve</h3>
            <p>
              Faleminderit që keni zgjedhur Buton për të bërë blerjet tuaja.
              Nëse keni ndonjë pyetje lidhur me procesin e blerjes, pagesat,
              transportin ose çështje të tjera lidhur me dyqanin tonë ju lutemi
              mos hezitoni te na kontaktoni. Me poshtë po ju listojm disa nga
              pyetjet me te shpeshta;
            </p>
            <h3>1. Ku Gjindet Buton?</h3>
            <p>
              {" "}
              Buton perveq blerjes online permes webfaqes www.butonks.com ka
              edhe 2 dyqane fizike nder me te medhat ne Kosove per shitjen e
              Teknologjise Informative
            </p>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <p>
                Buton 1 – Adresa: Rruga Elez Berisha, Kompleksi BDE Center,
                Fushe Kosove Telefoni: +38343997700
                <a
                href="https://www.google.com/maps/place/Buton/@42.6400059,21.1132883,21z/data=!4m6!3m5!1s0x13549f89e960f4d5:0x5576e927fca7ec72!8m2!3d42.6400207!4d21.1131259!16s%2Fg%2F11fp3jrgf4?entry=ttu
"
              >
                <br></br>
                 Lokacioni Google Maps: Kliko ketu!
              </a>
              </p>
        
              <p>
                {" "}
                Buton 2 – Adresa: Prishtina Mall Shopping Center, Kati 1
                Perendim Telefoni: +38348330440
                <br></br>
                <a href="https://www.google.com/maps/place/Prishtina+Mall/@42.5641165,21.1333178,19.47z/data=!4m6!3m5!1s0x13549dec301cb849:0xdf6994a48e64c161!8m2!3d42.5644737!4d21.1332794!16s%2Fg%2F11t6ldksgq?entry=ttu">
                 Lokacioni Goolgle Maps: Kliko ketu!
              </a>
              </p>
          
            </div>
          </div>
          <div>
            <h3> 2. Cilat janë mundësitë e pagesave?</h3>
            <p>
              Buton ju ofron lehtësi në pagesat tuaja, mënyra të pagesave për
              produktet e dëshiruara. - Paguaj me para në dorë (kesh) - Paguaj
              me transfer bankar - Paguaj me këste (TEB Starcard) - Paguaj me
              këste (Raiffeisen Bonus Kartelë) - Paguaj me këste (NLB Comfort
              Kartelë) - Paguaj me POS (ProCredit Bank) - Pagesa e kombinuar
              (Gjysma me para kesh, gjysma me Kartelë)
            </p>
            <h3>3. Si bëhet pagesa me këste? </h3>
            <p>
              Pagesa me këste për momentin ofrohet vetem per blerjet fizike në
              dyqan dhe/apo në qytetin e Prishtinës.
            </p>
            <h3>
              4. A mund të bëj porosi përmes telefonit, rrjeteve sociale apo
              live chat?
            </h3>

            <p>
              {" "}
              Porositë mund të bëhen vetëm përmes faqes son www.butonks.com Në
              rast se keni pengesa gjatë procesit të porosisë, ju mund të
              kontaktoni me agjentët tanë në rrjetet sociale, livechat apo
              permes telefonit dhe ata do te ju asistojn ne porosine tuaj.
            </p>

            <h3>
              {" "}
              5. Nëse dua të kthej një produkt, a duhet të paguaj transport?
            </h3>

            <p>
              {" "}
              Klienti duhet të sjellë vet produktin duke përfshirë paketimin e
              produktit, kuponin fiskal dhe të gjithë aksesorët përcjellës. Nese
              ju nuk mund te vini fizikisht ne nje nga dyqanet tona, at’her mund
              ta ktheni edhe me çfarëdo poste që juve ju përshtatet, vetëm se në
              këtë rast posta paguhet nga ana juaj.
            </p>
          </div>
          <div>
            <p>
              Për çfardo pyetje tjeter mund ta keni, ndjehuni te lire te na
              kerkoni pergjigje ne email apo permes kanaleve te tjera te
              komunikimit.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
