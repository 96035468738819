import React, { useEffect, useRef }  from 'react'
import { Product } from '../Product'
import Slider from "react-slick";
import Button from '../Button';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../../redux/app/products/trends/actions'

export default function PreferedModels({ rows = 2, heading }) {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const slider = useRef();
  const dispatch = useDispatch();
  const { isLoading, products, page } = useSelector(state => state.trends);

  useEffect(() => {
    dispatch(getProducts({ page, items_per_page: isMobile ? 10 : 20 }, true));
  }, [dispatch])

  const getMoreProducts = () => {
    dispatch(getProducts({ page: page + 1, items_per_page: 10 }));
  }

  const settings = {
    infinite: false,
    slidesToShow: 5,
    rows: rows,
    slidesPerRow: 1,
    initialSlide: 0,
    // lazyLoad: true,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          rows: products.length / 2
        }
      }
    ]
  };

  return (
    <div className={`all-products prefered-products ${ isLoading && 'loader' }`}>
      <div className='container'>
        <div className='section-heading'>
          <h2 className='green'>{ heading }</h2>
          { !isMobile && 
            <div className='d-flex arrows'>
              <Button 
                classes='d-flex align-center justify-center'
                title={
                  <svg xmlns="http://www.w3.org/2000/svg" width="5.766" height="11.255" viewBox="0 0 5.766 11.255">
                    <path id="fi-rr-angle-small-left" d="M10.151,11.2a.8.8,0,0,1,0-1.142l3.69-3.682a.8.8,0,1,0-1.133-1.142l-3.69,3.69a2.412,2.412,0,0,0,0,3.409l3.69,3.69a.8.8,0,1,0,1.133-1.142Z" transform="translate(-8.313 -4.999)" fill="#0e9548"/>
                  </svg>
                }
                onClick={ () => slider.current?.slickPrev() }   
              />
              <Button 
                classes='d-flex align-center justify-center'
                title={
                  <svg xmlns="http://www.w3.org/2000/svg" width="5.766" height="11.256" viewBox="0 0 5.766 11.256">
                    <path id="fi-rr-angle-small-right" d="M14.165,8.923l-3.69-3.69A.8.8,0,1,0,9.342,6.375l3.7,3.682a.8.8,0,0,1,0,1.142l-3.7,3.682a.8.8,0,0,0,1.133,1.142l3.69-3.69A2.412,2.412,0,0,0,14.165,8.923Z" transform="translate(-9.104 -4.999)" fill="#0e9548"/>
                  </svg>
                }
                onClick={ () => slider.current?.slickNext() }   
              />
            </div>
          }
        </div>
      </div>
      <div className='container'>
        { products.length > 0 && (
          <Slider ref={slider} {...settings} key={'ASFASF2232'}>
            { products.map(item => <Product item={item} key={item.product_id} />) }
          </Slider>
        )}
      </div>
      { isMobile && (
        <div className='d-flex justify-center'>
          <Button title="Shiko me shume" classes='outline blue no-border-radius' onClick={ getMoreProducts } /> 
        </div>
      )}
    </div>
  )
}