import React, { useState } from 'react'

const Menu = ({ item, checked, opened = false, handleFeatures }) => {
  const [isOpen, setOpen] = useState(opened);
  return (
    <li>
      <div className={`heading ${isOpen && 'active'}`} onClick={ () => setOpen(!isOpen) }>
        <span>{ item.filter }</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="13.072" height="6.697" viewBox="0 0 13.072 6.697">
          <path id="fi-rr-angle-small-left" d="M2.136,7.2a.934.934,0,0,1,0-1.326L6.421,1.6A.934.934,0,0,0,5.1.272L.819,4.557a2.8,2.8,0,0,0,0,3.959L5.1,12.8a.934.934,0,1,0,1.316-1.326Z" transform="translate(0 6.697) rotate(-90)" fill="#434343"/>
        </svg>
      </div>
      { isOpen && 
        <ul className='submenu'>
          { item.variants && Object.keys(item.variants).map(key => {
            return (
              <li key={ item.variants[key].variant_id }>
                <label className="custom-checkbox">{ item.variants[key].variant } 
                  <input
                    name="filters"
                    type="checkbox"
                    value={ item.variants[key].variant_id }
                    onChange={ (e) => handleFeatures(item.filter_id + '-' + e.target.value) }
                    checked={ checked.includes(item.filter_id + '-' + item.variants[key].variant_id) }
                  />
                  <span className="checkmark"></span>
                </label>
              </li>
            )
          })}
        </ul>
      }
    </li>
  )
}

export default Menu