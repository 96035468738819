import React from 'react'
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux'

export default function Pagination({ page, items_per_page, handleChange }) {
  const { params } = useSelector(state => state.search)
  
  return (
    <div className='pagination'>
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <svg xmlns="http://www.w3.org/2000/svg" width="9.997" height="9.971" viewBox="0 0 9.997 9.971">
            <g id="Group_238" data-name="Group 238" transform="translate(0.369 0.337)">
              <path id="Path_294" data-name="Path 294" d="M1803.615,4362.342l4.409,4.831-4.409,4.451" transform="translate(-1799.086 -4362.342)" fill="none" stroke="#0e9548" strokeWidth="1"/>
              <path id="Path_295" data-name="Path 295" d="M1803.615,4362.342l4.409,4.831-4.409,4.451" transform="translate(-1803.615 -4362.342)" fill="none" stroke="#0e9548" strokeWidth="1"/>
            </g>
          </svg>
        }
        onPageChange={ (e) => handleChange('page', e.selected + 1) }
        pageRangeDisplayed={3}
        pageCount={ params?.total_items ? Math.ceil(params?.total_items / items_per_page) : 1 }
        previousLabel={
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9.971" viewBox="0 0 10 9.971">
            <g id="Group_237" data-name="Group 237" transform="translate(0.69 0.337)">
              <path id="Path_294" data-name="Path 294" d="M1808.026,4362.342l-4.41,4.831,4.41,4.451" transform="translate(-1803.616 -4362.342)" fill="none" stroke="#0e9548" strokeWidth="1"/>
              <path id="Path_295" data-name="Path 295" d="M1808.026,4362.342l-4.41,4.831,4.41,4.451" transform="translate(-1799.085 -4362.342)" fill="none" stroke="#0e9548" strokeWidth="1"/>
            </g>
          </svg>
        }
        forcePage={ page - 1 }
        renderOnZeroPageCount={null}
        activeClassName='active'
      />
      <p>{ (page * items_per_page) > params?.total_items ? params?.total_items : (page * items_per_page) } nga { params?.total_items }</p>
    </div>
  )
}