import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { resetOrder } from '../../redux/app/orders/actions'

export default function CartSuccess() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { created, order_id } = useSelector(state => state.orders);

  useEffect(() => {
    if(!order_id || !created) navigate('/');
    return () => dispatch(resetOrder())
  }, [created, order_id])

  return (
    <div className='cart-success'>
      <div className='info'>
        <h2>Ju Faleminderit!</h2>
        <h5>Porosia #{ order_id } eshte regjistruar</h5>
        <p>Informacionet e blerjes suaj mund ti gjeni ne email</p>
        <Link to='/' className='outline green'>Kthehu ne balline</Link>
      </div>
      <img src="/assets/images/onlineshopping.png" alt="Online shopping" />
    </div>
  )
}