import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import { Outlet } from 'react-router-dom';

export default function Reset() {
  return (
    <>
      <Breadcrumbs active_page="Llogaria ime" crumbs={ [ { link: '/', title: 'Home' } ] } />
      <Outlet />
    </>
  )
}