import React, { useRef } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Button from "../Button";
import { useSelector } from "react-redux";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Categories({ isMobile }) {
  const slider = useRef();
  const { categories } = useSelector((state) => state.categories);

  var settings = {
    infinite: false,
    slidesToShow: 5,
    rows: 1,
    initialSlide: 0,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          rows: 4,
        },
      },
    ],
  };

  return (
    <div className="categories container">
      {!isMobile && (
        <div className="section-heading">
          <h2>Kategorite</h2>
          <div className="d-flex arrows">
            <Button
              classes="d-flex align-center justify-center"
              title={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.766"
                  height="11.255"
                  viewBox="0 0 5.766 11.255"
                >
                  <path
                    id="fi-rr-angle-small-left"
                    d="M10.151,11.2a.8.8,0,0,1,0-1.142l3.69-3.682a.8.8,0,1,0-1.133-1.142l-3.69,3.69a2.412,2.412,0,0,0,0,3.409l3.69,3.69a.8.8,0,1,0,1.133-1.142Z"
                    transform="translate(-8.313 -4.999)"
                    fill="#0e9548"
                  />
                </svg>
              }
              onClick={() => slider.current?.slickPrev()}
            />
            <Button
              classes="d-flex align-center justify-center"
              title={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.766"
                  height="11.256"
                  viewBox="0 0 5.766 11.256"
                >
                  <path
                    id="fi-rr-angle-small-right"
                    d="M14.165,8.923l-3.69-3.69A.8.8,0,1,0,9.342,6.375l3.7,3.682a.8.8,0,0,1,0,1.142l-3.7,3.682a.8.8,0,0,0,1.133,1.142l3.69-3.69A2.412,2.412,0,0,0,14.165,8.923Z"
                    transform="translate(-9.104 -4.999)"
                    fill="#0e9548"
                  />
                </svg>
              }
              onClick={() => slider.current?.slickNext()}
            />
          </div>
        </div>
      )}
      {categories && (
        <Slider ref={slider} {...settings}>
          {[...categories.keys()].map((item) => {
            const category = categories.get(item);
            return (
              <Link
                to={`/search?c=${category.category_id}`}
                className="item"
                key={category.category_id}
              >
                <div className="inner">
                  <img
                    src={category.main_pair?.detailed.image_path}
                    alt={category.category}
                  />
                  <h5>{category.category}</h5>
                </div>
              </Link>
            );
          })}
        </Slider>
      )}
    </div>
  );
}
