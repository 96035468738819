import React from 'react'
import { Link } from 'react-router-dom'

export default function Breadcrumbs({ active_page, crumbs = [], search = null }) {
  return (
    <div className='breadcrumbs'>
      <div className='container'>
        <ul>
          <li><Link to="/">Home</Link></li>
          { crumbs.map((item, key) => ( 
            <li key={ key }>
              <Link to={ search ? `/search?c=${item.id}` : item.link }>{ item.title }</Link>
            </li> 
          )) }
          { active_page && <li><Link to="#">{ active_page }</Link></li> }
        </ul>
      </div>
    </div>
  )
}