/********************
 ACTIONS
 ********************/
import {
    GET_SHIPPINGS,
    SET_SHIPPINGS
} from './types'

export const getShippings = (shippings) => ({
    type: GET_SHIPPINGS,
    shippings
});

export const setShippings = (shippings) => ({
    type: SET_SHIPPINGS,
    shippings
});
