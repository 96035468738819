import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFound() {
  return (
    <div className='container'>
      <div className='d-flex flex-column align-center'>
        <img src='/assets/images/notfound.png' alt="404" className='not-found-img' />
        <Link className='outline blue' to='/'>Kthehu ne balline</Link>
      </div>
    </div>
  )
}