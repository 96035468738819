import React, { useState, useEffect } from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import PreferedModels from '../components/Home/PreferedModels'
import CompareTable from '../components/CompareTable'
import { useSelector, useDispatch } from 'react-redux';
import { getCompare } from "../redux/app/compare/actions";
import { fixPrice } from '../utils/number'
import Loader from '../components/Loader'

export default function Compare() {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [features, setFeatures] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const { compareProducts } = useSelector((state) => state.compare);

  useEffect(() => {
    dispatch(getCompare());
  }, [])

  useEffect(() => {
    let array = [];
    let features = { 'price': 'Price' };
    compareProducts.map(item => {
      let obj = {
        id: item.product_id,
        image: item.main_pair.detailed.image_path,
        name: item.product,
        price: fixPrice(item.price)
      }
      Object.keys(item.product_features).map(item2 => {
        let prd = item.product_features[item2];
        
        obj[prd.feature_id] = prd.variant_id !== "0" ? prd.variants[prd.variant_id].variant : prd.value;
        features[prd.feature_id] = prd.description
      })

      array.push(obj);
    })

    setProducts(array);
    setFeatures(features);
    if(array.length <= 0) setLoading(false);
  }, [compareProducts])

  return (
    <>
      { isLoading && <Loader /> }
      <Breadcrumbs active_page="Krahaso produktin" />
      <div className='container'>
        <h3 className='page-title'>Krahaso produktin</h3>
        <div className='cart-table-container'>
          <div className="compare-table">
            { products.length > 0 && 
              <CompareTable 
                data={products} 
                features={features}
                setLoading={ setLoading }
              />
            }
            { products.length <= 0 && <p>Lista juaj e krahasimeve eshte e zbrazet!</p>}
          </div>
        </div>
      </div>
      <PreferedModels rows={ 1 } heading='Trendet e fundit' />
    </>
  )
}