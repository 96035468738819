import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";
import { SmallProduct } from "../Product";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Quantityx } from "../Quantity";
import Button from "../Button";
import { useSelector, useDispatch } from "react-redux";
import { fixPrice } from "../../utils/number";
import { getCoupon, removeProductFromCart } from "../../redux/app/cart/actions";
import { ErrorMessage } from "../Custom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ConfirmModal from "../ConfirmModal";

export default function CartIndex() {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const {
    cartProducts,
    coupon,
    subtotal,
    couponDiscount,
    isCouponLoading,
    total,
    length,
  } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const CouponValidation = Yup.object().shape({
    coupon: Yup.string().required(),
  });

  const handleSubmit = (values) => {
    dispatch(getCoupon(values.coupon));
  };

  const handleBuy = (e) => {
    if (!isAuthenticated) {
      navigate("/login", { state: { from: "/cart/buy" } });
      e.preventDefault();
    }
  };

  const handleRemove = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            onClose={onClose}
            onRemove={() => dispatch(removeProductFromCart(id))}
          />
        );
      },
      overlayClassName: "confirmation-modal-overlay center",
    });
  };

  return (
    <>
      <Breadcrumbs active_page="Shporta" crumbs={[]} />
      <div className="container cart-container">
        <h3 className="page-title">Shporta</h3>
        <div className="cart-table-container">
          <table className="cart">
            <thead>
              <tr>
                <th className="mobile-header">Produkti</th>
                <th>Cmimi per njesi</th>
                <th>Sasia</th>
                <th>Cmimi total</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {cartProducts &&
                cartProducts.map((item) => (
                  <tr key={item.product_id}>
                    <td className="product-image">
                      <div className="small-products">
                        <SmallProduct item={item} />
                      </div>
                      <p style={{ fontSize: "12px", marginLeft: "16.5%" }}>
                        Product Id: {item.product_id}
                      </p>
                    </td>
                    <td>
                      <span>Cmimi per njesi</span>
                      {fixPrice(item.price)}&euro;
                    </td>
                    <td>
                      <span>Cmimi total</span>
                      <Quantityx
                        val={item.quantity}
                        id={item.product_id}
                        max={item.amount}
                      />
                    </td>
                    <td className="price mobile-border">
                      <span>Cmimi total</span>
                      {fixPrice(item.price * item.quantity)}&euro;
                    </td>
                    <td className="remove-cart">
                      <Button
                        classes="remove-btn"
                        onClick={() =>
                          handleRemove(item?.id ? item.id : item.product_id)
                        }
                        icon={
                          <svg
                            id="fi-rr-trash"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.724"
                            height="20.069"
                            viewBox="0 0 16.724 20.069"
                          >
                            <path
                              id="Path_658"
                              data-name="Path 658"
                              d="M17.888,3.345H15.3A4.189,4.189,0,0,0,11.2,0H9.526a4.189,4.189,0,0,0-4.1,3.345H2.836a.836.836,0,1,0,0,1.672h.836V15.888a4.186,4.186,0,0,0,4.181,4.181h5.017a4.186,4.186,0,0,0,4.181-4.181V5.017h.836a.836.836,0,0,0,0-1.672ZM9.526,1.672H11.2a2.514,2.514,0,0,1,2.366,1.672H7.16A2.514,2.514,0,0,1,9.526,1.672Zm5.853,14.215A2.509,2.509,0,0,1,12.871,18.4H7.853a2.509,2.509,0,0,1-2.509-2.509V5.017H15.379Z"
                              transform="translate(-2)"
                              fill="#ddd"
                            />
                            <path
                              id="Path_659"
                              data-name="Path 659"
                              d="M9.836,16.69a.836.836,0,0,0,.836-.836V10.836a.836.836,0,0,0-1.672,0v5.017A.836.836,0,0,0,9.836,16.69Z"
                              transform="translate(-3.147 -1.638)"
                              fill="#ddd"
                            />
                            <path
                              id="Path_660"
                              data-name="Path 660"
                              d="M13.836,16.69a.836.836,0,0,0,.836-.836V10.836a.836.836,0,0,0-1.672,0v5.017A.836.836,0,0,0,13.836,16.69Z"
                              transform="translate(-3.802 -1.638)"
                              fill="#ddd"
                            />
                          </svg>
                        }
                      />
                    </td>
                  </tr>
                ))}
              {length <= 0 && (
                <tr>
                  <td colSpan="5" align="center">
                    Shporta juaj eshte e zbrazet.
                  </td>
                </tr>
              )}
            </tbody>
            {length > 0 && (
              <tfoot>
                <tr>
                  <td colSpan={5}>
                    <div className="footer-container">
                      <div className="gift-card">
                        <label htmlFor="coupon">
                          Gift certificate or promo code
                        </label>
                        <Formik
                          initialValues={{ coupon: coupon?.name || "" }}
                          validationSchema={CouponValidation}
                          onSubmit={handleSubmit}
                        >
                          {({ errors, touched }) => (
                            <>
                              <Form className="coupon">
                                <Field
                                  type="text"
                                  id="coupon"
                                  name="coupon"
                                  placeholder="e.g. xxxxxxx"
                                />
                                <Button
                                  classes="green"
                                  title="Shto"
                                  type="submit"
                                  loading={isCouponLoading}
                                />
                              </Form>
                              {errors.coupon && touched.coupon && (
                                <ErrorMessage message={errors.coupon} />
                              )}
                            </>
                          )}
                        </Formik>
                      </div>
                      <div className="totals">
                        <p className="others">Transporti: Falas</p>
                        <p className="others">
                          Nen totali: <b>{fixPrice(subtotal)} &euro;</b>
                        </p>
                        {couponDiscount > 0 && (
                          <p className="others">
                            Zbritje kuponi:{" "}
                            <b>-{fixPrice(couponDiscount)} &euro;</b>
                          </p>
                        )}
                        <div className="total_buy">
                          <p>
                            Totali: <span>{fixPrice(total)} &euro;</span>
                          </p>
                          <Link
                            className="btn green"
                            to="buy"
                            onClick={handleBuy}
                          >
                            Blej menjehere
                          </Link>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    </>
  );
}
