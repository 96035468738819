import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { getFeatured } from "../../redux/app/products/featured/actions";

export default function Featured() {
  const [active, setActive] = useState('newest');
  const { featured, isLoading } = useSelector((state) => state.featured);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFeatured(8, active));
  }, [active])


  const Button = ({ title, count, value, active, setActive }) => {
    const handleClick = () => {
      setActive(value);
    }

    return (
      <button type='button' className={ active === value ? 'active' : null } onClick={ handleClick }>
        { title }
        {/* <span>({ count })</span> */}
      </button>
    )
  }

  const Item = ({ item }) => (
    <Link to={`/product/${item.product_id}`} className='item'>
      <img src={ item.main_pair.detailed.image_path } alt={ item.product } />
      <h6>{ item.product }</h6>
    </Link>
  )

  return (
    <div className={`featured-products ${ isLoading && 'loader' }`}>
      <div className='container'>
        <div className='actions'>
          <Button title='Me te rejat' count={0} value='newest' active={ active } setActive={ setActive } />
          <Button title='Me te shikuarat' count={0} value='mostViewd' active={ active } setActive={ setActive } />
          <Button title='Me te kerkuarat' count={0} value='mostSearched' active={ active } setActive={ setActive } />
        </div>
        <div className='products'>
          { featured && featured.map(item => <Item key={ item.product_id } item={ item } />) }
        </div>
      </div>
    </div>
  )
}