import React from "react";

export default function PcBuilder() {
  return (
    <div className="container">
      <h1 className="page-title">Order Preview</h1>
      <div className="pc-builder-container">
        <div className="pc-builder-first">cont 1</div>
        <div className="pc-builder-second">
          <div className="cart">
            <div className="prices">
                    <div className="heading" >
                      <h1 className="price">
                       1 $
                      </h1>
                    </div>
              <p className="tvsh-included">* Përfshirë TVSH-në</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
