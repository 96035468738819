import React from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import { useSelector, useDispatch } from "react-redux";
import { addProductToCart } from "../redux/app/cart/actions";
import { addProductToWishlist } from "../redux/app/wishlist/actions";
import { fixPrice } from "../utils/number";

export const SmallProduct = ({
  item,
  showPrice = false,
  showQty = false,
  onClick = null,
}) => (
  <div className="position-relative">
    <Link to={`/product/${item.product_id}`} className="item">
      <div className="image-container">
        <img
          src={item.image ? item.image : item.main_pair?.detailed.image_path}
          alt={item.product}
        />
      </div>
      <div>
        <p>{item.product}</p>
        {showPrice && (
          <p className="price">
            {showQty && item.quantity + " x"} {fixPrice(item.price)} &euro;
          </p>
        )}
      </div>
    </Link>
    {onClick && (
      <Button
        onClick={onClick}
        icon={
          <svg
            id="fi-rr-trash"
            xmlns="http://www.w3.org/2000/svg"
            width="16.724"
            height="20.069"
            viewBox="0 0 16.724 20.069"
          >
            <path
              id="Path_658"
              data-name="Path 658"
              d="M17.888,3.345H15.3A4.189,4.189,0,0,0,11.2,0H9.526a4.189,4.189,0,0,0-4.1,3.345H2.836a.836.836,0,1,0,0,1.672h.836V15.888a4.186,4.186,0,0,0,4.181,4.181h5.017a4.186,4.186,0,0,0,4.181-4.181V5.017h.836a.836.836,0,0,0,0-1.672ZM9.526,1.672H11.2a2.514,2.514,0,0,1,2.366,1.672H7.16A2.514,2.514,0,0,1,9.526,1.672Zm5.853,14.215A2.509,2.509,0,0,1,12.871,18.4H7.853a2.509,2.509,0,0,1-2.509-2.509V5.017H15.379Z"
              transform="translate(-2)"
              fill="#ddd"
            />
            <path
              id="Path_659"
              data-name="Path 659"
              d="M9.836,16.69a.836.836,0,0,0,.836-.836V10.836a.836.836,0,0,0-1.672,0v5.017A.836.836,0,0,0,9.836,16.69Z"
              transform="translate(-3.147 -1.638)"
              fill="#ddd"
            />
            <path
              id="Path_660"
              data-name="Path 660"
              d="M13.836,16.69a.836.836,0,0,0,.836-.836V10.836a.836.836,0,0,0-1.672,0v5.017A.836.836,0,0,0,13.836,16.69Z"
              transform="translate(-3.802 -1.638)"
              fill="#ddd"
            />
          </svg>
        }
      />
    )}
  </div>
);

export const Product = ({ item }) => {
  const dispatch = useDispatch();
  const { cartProducts, added: addedCart } = useSelector((state) => state.cart);
  const { wishlistProducts, added: addedWishlist } = useSelector(
    (state) => state.wishlist
  );

  const inCart = (productId) =>
    cartProducts && cartProducts.find((item) => item.product_id === productId);
  const inWishlist = (productId) =>
    wishlistProducts &&
    wishlistProducts.find((item) => item.product_id === productId);
  React.useEffect(() => {}, []);
  return (
    <div className="product">
      <div className="info">
        {item.list_discount_prc > 0 && (
          <span className="tag-discount">-{item.list_discount_prc}%</span>
        )}
        {item.promotions &&
          Object.keys(item.promotions).map((promotionKey) => (
            <span className="tag-discount">
              -{item.promotions[promotionKey].bonuses[0].discount_value}%
            </span>
          ))}

        <Link to={`/product/${item.product_id}`} className="image">
          {item.amount == 0 && item.out_of_stock_actions == "S" ? (
            <div className="soldWrapper">
              <p className="soldText">E shitur</p>
              <img
                src={item.main_pair?.detailed.image_path}
                alt={item.product}
                loading="lazy"
                className="soldImage"
              />
            </div>
          ) : (
            <img
              src={item.main_pair?.detailed.image_path}
              alt={item.product}
              loading="lazy"
            />
          )}
        </Link>
        <div className="heading">
          <p className="title">{item.product}</p>
          {item.promotions ? (
            Object.keys(item.promotions).map((promotionKey) => {
              return (
                <div className="">
                  <span className="price">{fixPrice(item.price)}€</span>
                  <span className="old-price">{item.base_price}€</span>
                </div>
              );
            })
          ) : (
            <>
              <span className="price">
                {item.price !== "0.00"
                  ? fixPrice(item.price) + " \u20AC"
                  : "Contact us for price"}
              </span>
              {item.list_discount_prc > 0 && (
                <span className="old-price">
                  {fixPrice(item.list_price)}&euro;
                </span>
              )}
            </>
          )}
        </div>
      </div>

      <div className="actions">
        {item.price !== "0.00" &&
          item.amount > 0 &&
          item.out_of_stock_actions == "S" && (
            <Button
              classes={inCart(item.product_id) && "active"}
              onClick={() =>
                dispatch(
                  addProductToCart(item.product_id, 1, false, item.amount)
                )
              }
              loading={addedCart === item.product_id}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.972"
                  height="17.972"
                  viewBox="0 0 17.972 17.972"
                >
                  <g
                    id="Group_367"
                    data-name="Group 367"
                    transform="translate(20288.973 -3060)"
                  >
                    <path
                      id="Rectangle_1561"
                      data-name="Rectangle 1561"
                      d="M1,0H15a1,1,0,0,1,1,1V9a2,2,0,0,1-2,2H2A2,2,0,0,1,0,9V1A1,1,0,0,1,1,0Z"
                      transform="translate(-20288 3066)"
                      fill="transparent"
                    />
                    <path
                      id="fi-rr-shopping-bag"
                      d="M15.726,4.493H13.479a4.493,4.493,0,1,0-8.986,0H2.247A2.247,2.247,0,0,0,0,6.74v7.488a3.749,3.749,0,0,0,3.744,3.744H14.228a3.749,3.749,0,0,0,3.744-3.744V6.74A2.247,2.247,0,0,0,15.726,4.493Zm-6.74-3a3,3,0,0,1,3,3H5.991A3,3,0,0,1,8.986,1.5Zm7.488,12.73a2.247,2.247,0,0,1-2.247,2.247H3.744A2.247,2.247,0,0,1,1.5,14.228V6.74a.749.749,0,0,1,.749-.749H4.493v1.5a.749.749,0,0,0,1.5,0v-1.5h5.991v1.5a.749.749,0,0,0,1.5,0v-1.5h2.247a.749.749,0,0,1,.749.749Z"
                      transform="translate(-20288.973 3060)"
                      fill="#888"
                    />
                  </g>
                </svg>
              }
            />
          )}
        {item.price !== "0.00" &&
          item.amount == 0 &&
          item.out_of_stock_actions == "B" && (
            <Button
              classes={inCart(item.product_id) && "active"}
              onClick={() =>
                dispatch(
                  addProductToCart(item.product_id, 1, false, item.amount)
                )
              }
              loading={addedCart === item.product_id}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.972"
                  height="17.972"
                  viewBox="0 0 17.972 17.972"
                >
                  <g
                    id="Group_367"
                    data-name="Group 367"
                    transform="translate(20288.973 -3060)"
                  >
                    <path
                      id="Rectangle_1561"
                      data-name="Rectangle 1561"
                      d="M1,0H15a1,1,0,0,1,1,1V9a2,2,0,0,1-2,2H2A2,2,0,0,1,0,9V1A1,1,0,0,1,1,0Z"
                      transform="translate(-20288 3066)"
                      fill="transparent"
                    />
                    <path
                      id="fi-rr-shopping-bag"
                      d="M15.726,4.493H13.479a4.493,4.493,0,1,0-8.986,0H2.247A2.247,2.247,0,0,0,0,6.74v7.488a3.749,3.749,0,0,0,3.744,3.744H14.228a3.749,3.749,0,0,0,3.744-3.744V6.74A2.247,2.247,0,0,0,15.726,4.493Zm-6.74-3a3,3,0,0,1,3,3H5.991A3,3,0,0,1,8.986,1.5Zm7.488,12.73a2.247,2.247,0,0,1-2.247,2.247H3.744A2.247,2.247,0,0,1,1.5,14.228V6.74a.749.749,0,0,1,.749-.749H4.493v1.5a.749.749,0,0,0,1.5,0v-1.5h5.991v1.5a.749.749,0,0,0,1.5,0v-1.5h2.247a.749.749,0,0,1,.749.749Z"
                      transform="translate(-20288.973 3060)"
                      fill="#888"
                    />
                  </g>
                </svg>
              }
            />
          )}
        <Button
          classes={
            inWishlist(item.product_id)
              ? "active"
              : "" + item.price === "0.00"
              ? " no-price"
              : ""
          }
          onClick={() => dispatch(addProductToWishlist(item.product_id))}
          loading={addedWishlist === item.product_id}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.486"
              height="18"
              viewBox="0 0 20.486 18"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectangle_1565"
                    data-name="Rectangle 1565"
                    width="17.357"
                    height="14.826"
                    fill="#fafafa"
                  />
                </clipPath>
              </defs>
              <g
                id="Group_369"
                data-name="Group 369"
                transform="translate(-396.001 -2194)"
              >
                <g
                  id="Group_366"
                  data-name="Group 366"
                  transform="translate(397.566 2195.586)"
                >
                  <g
                    id="Group_365"
                    data-name="Group 365"
                    transform="translate(0 0)"
                    clipPath="url(#clip-path)"
                  >
                    <path
                      id="Path_737"
                      data-name="Path 737"
                      d="M10.34,14.221a2.578,2.578,0,0,1-3.321,0C2.563,10.483.006,6.9.006,4.379A4.167,4.167,0,0,1,3.909,0a4.472,4.472,0,0,1,3.9,3.157.867.867,0,1,0,1.734,0A4.528,4.528,0,0,1,13.448,0a4.167,4.167,0,0,1,3.9,4.379c0,2.517-2.556,6.1-7.012,9.839Z"
                      transform="translate(0 0)"
                      fill="transparent"
                    />
                  </g>
                </g>
                <path
                  id="fi-rr-heart"
                  d="M14.931,1.917a5.461,5.461,0,0,0-4.693,2.816A5.461,5.461,0,0,0,5.545,1.917,5.8,5.8,0,0,0,0,7.932c0,3.88,4.084,8.117,7.509,10.99a4.244,4.244,0,0,0,5.461,0c3.425-2.873,7.509-7.11,7.509-10.99a5.8,5.8,0,0,0-5.546-6.015Zm-3.059,15.7a2.537,2.537,0,0,1-3.268,0c-4.384-3.678-6.9-7.207-6.9-9.684a4.1,4.1,0,0,1,3.84-4.309A4.4,4.4,0,0,1,9.385,6.73a.853.853,0,0,0,1.706,0,4.456,4.456,0,0,1,3.84-3.106,4.1,4.1,0,0,1,3.84,4.309c0,2.477-2.515,6.006-6.9,9.681Z"
                  transform="translate(396.006 2192.083)"
                  fill="#888"
                />
              </g>
            </svg>
          }
        />
        <Link to={`/product/${item.product_id}`}>Shko te detajet</Link>
      </div>
    </div>
  );
};

export const WishlistItem = ({ item, addToCart, removeFromWishlist }) => (
  <div className="product">
    <div className="info">
      {item.list_discount_prc > 0 && item.amount > 0 && (
        <span className="tag-discount">-{item.list_discount_prc}%</span>
      )}
      <Button
        classes="remove-item"
        onClick={removeFromWishlist}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="11"
            viewBox="0 0 11 11"
          >
            <path
              id="fi-rr-cross-small"
              d="M16.451,5.963h0a.874.874,0,0,0-1.236,0L11.207,9.971,7.2,5.963a.874.874,0,0,0-1.236,0h0a.874.874,0,0,0,0,1.236l4.008,4.008L5.963,15.215a.874.874,0,0,0,0,1.236h0a.874.874,0,0,0,1.236,0l4.008-4.008,4.008,4.008a.874.874,0,0,0,1.236,0h0a.874.874,0,0,0,0-1.236l-4.008-4.008L16.451,7.2A.874.874,0,0,0,16.451,5.963Z"
              transform="translate(-5.707 -5.707)"
              fill="#ddd"
            />
          </svg>
        }
      />
      <div className="image">
        <img src={item.image} alt={item.title} />
      </div>
      <div className="heading">
        <span className="price"> {fixPrice(item.price)} &euro;</span>
        {item.list_discount_prc > 0 && (
          <span className="old-price">{fixPrice(item.list_price)} &euro;</span>
        )}
        <p className="title">{item.product}</p>
      </div>
    </div>

    <div className="actions">
      <Button
        classes="add-cart"
        title="Shto ne shporte"
        onClick={addToCart}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.972"
            height="17.972"
            viewBox="0 0 17.972 17.972"
          >
            <g
              id="Group_367"
              data-name="Group 367"
              transform="translate(20288.973 -3060)"
            >
              <path
                id="Rectangle_1561"
                data-name="Rectangle 1561"
                d="M1,0H15a1,1,0,0,1,1,1V9a2,2,0,0,1-2,2H2A2,2,0,0,1,0,9V1A1,1,0,0,1,1,0Z"
                transform="translate(-20288 3066)"
                fill="transparent"
              />
              <path
                id="fi-rr-shopping-bag"
                d="M15.726,4.493H13.479a4.493,4.493,0,1,0-8.986,0H2.247A2.247,2.247,0,0,0,0,6.74v7.488a3.749,3.749,0,0,0,3.744,3.744H14.228a3.749,3.749,0,0,0,3.744-3.744V6.74A2.247,2.247,0,0,0,15.726,4.493Zm-6.74-3a3,3,0,0,1,3,3H5.991A3,3,0,0,1,8.986,1.5Zm7.488,12.73a2.247,2.247,0,0,1-2.247,2.247H3.744A2.247,2.247,0,0,1,1.5,14.228V6.74a.749.749,0,0,1,.749-.749H4.493v1.5a.749.749,0,0,0,1.5,0v-1.5h5.991v1.5a.749.749,0,0,0,1.5,0v-1.5h2.247a.749.749,0,0,1,.749.749Z"
                transform="translate(-20288.973 3060)"
                fill="#fff"
              />
            </g>
          </svg>
        }
      />
    </div>
  </div>
);
