import { 
  UPDATE_WISHLIST,
  REMOVE_PRODUCT_WISHLIST,
  ADD_PRODUCT_WISHLIST,
  SET_WISHLIST_LENGTH,
  SET_WISHLIST_ADDED
} from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
  wishlistProducts: [],
  length: 0,
  added: null
};

export const wishlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WISHLIST_LENGTH:
      return {
        ...state,
        length: action.length
      };
    case SET_WISHLIST_ADDED:
      return {
        ...state,
        added: null
      };
    case ADD_PRODUCT_WISHLIST:
      return {
        ...state,
        added: action.productId
      }
    case UPDATE_WISHLIST:
      return {
        ...state,
        wishlistProducts: [...state.wishlistProducts, action.product],
        added: null
      };
    case REMOVE_PRODUCT_WISHLIST:
      return {
        ...state,
        wishlistProducts: state.wishlistProducts.filter(item => action.productId !== item.product_id),
        length: state.length - 1
      };
    default:
      return state
  }
};

export default wishlistReducer;