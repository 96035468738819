import { put, call, all, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { getCart, updateCart, setCartLength, productInc, resetAdded, calcTotal, setCoupon, clearCoupon } from './actions'
import { 
  ADD_PRODUCT,
  GET_CART,
  REMOVE_PRODUCT,
  CART_INC,
  CART_DEC,
  ADD_ALL_TO_WISHLIST,
  ADD_TO_DB,
  GET_COUPON,
  SET_COUPON,
  CLEAR_COUPON,
  CLEAR_CART_DB,
  COUPON_INIT
} from './types'
import { setMessage } from '../custom/actions'
import api, { user_api } from '../../../utils/axios'
import { addProductToWishlist } from '../wishlist/actions';

// watcher
export function* cartWatcher() {
  yield takeEvery(GET_CART, INIT);
  yield takeEvery(ADD_PRODUCT, ADDPRODUCT);
  yield takeEvery(REMOVE_PRODUCT, REMOVEPRODUCT);
  
  yield takeLatest(CART_INC, UPDATESTORAGE);
  yield takeLatest(CART_DEC, UPDATESTORAGE);

  yield takeEvery(ADD_ALL_TO_WISHLIST, ADDALLTOWISHLIST);
  yield takeLatest(ADD_TO_DB, ADDTODB);

  yield takeLatest(CLEAR_CART_DB, CLEARCARTDB);

  yield takeLatest(COUPON_INIT, COUPONINIT);
  yield takeLatest(GET_COUPON, ADDCOUPON);
  yield takeLatest(SET_COUPON, SETCOUPON);
  yield takeLatest(CLEAR_COUPON, CLEARCOUPON);
}

function* isAuth() {
  const isAuthenticated = yield select(state => state.auth.isAuthenticated)
  return isAuthenticated;
}

export function* ADDTODB() {
  try {
    let cart = JSON.parse(yield localStorage.getItem("cart"));
    if(cart){
      let products = {};
      cart.forEach(item => {
        products[item.productId] = {
          product_id: item.productId,
          amount: item.quantity
        }
      })
      yield user_api.post(`/4.0/sra_cart_content`, { products });
    }
    yield put(getCart())
  } catch (e){
    console.log(e);
  }
}

export function* UPDATESTORAGE({ type, productId, quantity, force }) {
  try {
    if(yield isAuth()){
      if(type === CART_DEC){
        const product = yield select(state => state.cart.cartProducts);
        const p = product.find(item => item.product_id === productId)
        quantity = p.quantity;
      }
      yield user_api.post(`/4.0/sra_cart_content`, {
        products: {
          [productId]: {
            product_id: productId,
            amount: quantity,
            force: type === CART_DEC ? true : false
          }
        }
      });
    } else {
      let cart = JSON.parse(yield localStorage.getItem("cart"));
      if(cart){
        const new_cart = cart.map(item => {
          if(item.productId === productId){
            if(type === CART_INC) {
              if(force) item.quantity = quantity
              else item.quantity += quantity
            }
            else if(type === CART_DEC && item.quantity > 1) {
              if(force) item.quantity = quantity
              else item.quantity -= quantity
            }
          }
          return item;
        })
        yield localStorage.setItem("cart", JSON.stringify(new_cart))
      }
    }
    yield put(calcTotal());
  } catch (e){
    console.log(e);
  }
}

// worker
export function* INIT() { // done with db 
  try {
    if(yield isAuth()){
      const response = (yield user_api.get('/4.0/sra_cart_content')).data;
      if(response.products){
        const products = Object.keys(response.products).map(item => {
          const product = response.products[item];
          return {
            id: item,
            product_id: product.product_id,
            product: product.product,
            price: product.price,
            quantity: product.amount,
            image: product.main_pair.detailed.image_path,
            amount: product.in_stock
          }
        })
  
        yield put(setCartLength(products.length))
        yield put(updateCart(products))
      }
    } else {
      let cart = JSON.parse(yield localStorage.getItem("cart"));
      if(cart) { 
        yield put(setCartLength(cart.length));
        for(let item of cart){
          yield call(ADDTOCART, item);
        }
      }
    }
    yield put(calcTotal());
  } catch (e){
    console.log(e);
  }
}

function* ADDTOCART(item) {
  const product = (yield api.get(`/products/${item.productId}`)).data;
  yield put(updateCart({
    product_id: item.productId,
    product: product.product,
    price: product.price,
    quantity: item.quantity,
    image: product.main_pair.detailed.image_path
  }))
}

function* ADDPRODUCT({ productId, quantity = 1, force, max, showMsg }) {
  try {
    if(yield isAuth()){
      // add to db
      const cartProducts = yield select(state => state.cart.cartProducts)
      const product = cartProducts.find(item => item.product_id === productId);
      
      console.log(product);

      if(product && product.quantity === +product.amount){
        if(showMsg) yield put(setMessage('info', 'Keni arritur maksimumin e sasise.'))
      } else {
        yield user_api.post('/4.0/sra_cart_content', { products: {
          [productId]: {
            product_id: productId,
            amount: quantity,
            force
          }
        }});
        if(product && showMsg) yield put(setMessage('success', 'Produkti tashme ekziston, sasia u ndryshua.'))
        else if(showMsg) yield put(setMessage('success', 'Produkti u shtua ne shporte.'))
      }
      yield put(getCart())
    } else {
      // add to localStorage
      let cart = JSON.parse(yield localStorage.getItem("cart"));
      if(!cart) cart = [];
      const index = cart.findIndex(item => item.productId === productId);
      if(index !== -1) {
        if(cart[index].quantity >= max){
          if(showMsg) yield put(setMessage('warning', 'Keni arritur limitin e produkteve te lejuara ne shporte.'))
          yield put(resetAdded())
        } else {
          yield put(productInc(productId, quantity, force))
          if(showMsg) yield put(setMessage('info', 'Produkti tashme ekziston, sasia u ndryshua.'))
        }
      } else {
        cart.push({ productId, quantity: quantity });
        yield localStorage.setItem("cart", JSON.stringify(cart))
        yield put(setCartLength(cart.length));
        yield call(ADDTOCART, { productId, quantity: quantity });
        if(showMsg) yield put(setMessage('success', 'Produkti u shtua ne shporte.'))
      }
    }
    yield put(calcTotal());
  } catch (e){
    console.log(e);
  }
}

function* REMOVEPRODUCT({ productId }) {
  try {
    if(yield isAuth()){
      yield user_api.delete(`/4.0/sra_cart_content/${productId}`);
      yield put(setMessage('success', 'Produkti u fshi nga shporta.'))
    } else {
      // remove from localStorage
      let cart = JSON.parse(yield localStorage.getItem("cart"));
      if(cart){
        const index = cart.findIndex(item => item.productId === productId);
        if(index !== -1) {
          cart.splice(index, 1);
          yield localStorage.setItem("cart", JSON.stringify(cart))
          yield put(setMessage('success', 'Produkti u fshi nga shporta.'))
        }
      } 
    }
    yield put(calcTotal());
  } catch (e){
    console.log(e);
  }
}

function* ADDTOWISHLIST({ cart, productId }){
  if(cart){
    yield all(cart.map(item => {
      return put(addProductToWishlist(item[productId], false));
    }))
    yield put(setMessage('info', 'Lista e deshirave eshte perditesuar.'))
  }
}

function* ADDALLTOWISHLIST() {
  try {
    if(yield isAuth()){
      let cart = yield select(state => state.cart.cartProducts)
      yield call(ADDTOWISHLIST, { cart, productId: 'product_id' });
    }else {
      let cart = JSON.parse(yield localStorage.getItem("cart"));
      yield call(ADDTOWISHLIST, { cart, productId: 'productId' });
    }
  } catch (e){
    console.log(e);
  }
}

function* COUPONINIT(){
  try {
    const coupon = yield localStorage.getItem("coupon");
    if(coupon){
      const data = (yield api.get('/4.0/promotions', {
        params: { coupon_code: coupon, expand: true }
      })).data;
      if(data.promotions.length > 0){
        yield put(setCoupon(data.promotions[0]))
      } else {
        yield put(clearCoupon());
      }
      yield put(calcTotal())
    }
  } catch (e){
    console.log(e.message);
    yield put(clearCoupon());
    yield put(calcTotal())
  }
}
function* ADDCOUPON({ coupon }){
  try {
    const data = (yield api.get('/4.0/promotions', {
      params: { coupon_code: coupon, expand: true }
    })).data;

    if(data.promotions.length > 0){
      yield put(setCoupon(data.promotions[0]))
      yield put(setMessage('success', 'Kuponi u shtua me suksese'))
    } else {
      yield put(clearCoupon());
      yield put(setMessage('warning', 'Kuponi nuk ekziston!'))
    }
    yield put(calcTotal())
  } catch (e){
    console.log(e.message);
    yield put(clearCoupon());
    yield put(calcTotal())
  }
}

function* SETCOUPON({ coupon }){
  try {
    yield localStorage.setItem("coupon", coupon.name);
  } catch (e){
    console.log(e.message);
  }
}

function* CLEARCOUPON(){
  try {
    yield localStorage.removeItem("coupon");
  } catch (e){
    console.log(e.message);
  }
}

function* CLEARCARTDB(){
  try {
    const user = yield select(state => state.auth.user)
    yield user_api.delete(`/carts/${user.id}`);
  } catch (e){
    console.log(e.message);
  }
}