import React, { useState } from 'react'
import { SmallProduct } from '../Product';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { removeProductFromWishlist } from "../../redux/app/wishlist/actions";
import { addAllToCart } from "../../redux/app/wishlist/actions";
import Button from '../Button';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ConfirmModal from '../ConfirmModal';

export default function SideWishlist({ setVisibility }) {
  const { wishlistProducts } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();

  const handleRemove = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal onClose={ onClose } onRemove={ () => dispatch(removeProductFromWishlist(id)) } />
        );
      },
      overlayClassName: 'confirmation-modal-overlay'
    });
  }

  return (
    <>
      <div className='items'>
        <div className='header'>
          <h5>Lista e deshirave ({ ('0' + wishlistProducts.length).slice(-2) })</h5>
          <Button 
            classes='c-pointer'
            onClick={ () => setVisibility(false) }
            icon= { 
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                <path id="fi-rr-cross-small" d="M15.475,5.94h0a.8.8,0,0,0-1.124,0L10.707,9.584,7.063,5.94a.8.8,0,0,0-1.124,0h0a.8.8,0,0,0,0,1.124l3.644,3.644L5.94,14.351a.8.8,0,0,0,0,1.124h0a.8.8,0,0,0,1.124,0l3.644-3.644,3.644,3.644a.8.8,0,0,0,1.124,0h0a.8.8,0,0,0,0-1.124l-3.644-3.644,3.644-3.644A.8.8,0,0,0,15.475,5.94Z" transform="translate(-5.707 -5.707)" opacity="0.4"/>
              </svg>
            }
          />
        </div>

        <div className='small-products'>
          <div className="search-scroll">
            { wishlistProducts && wishlistProducts.map(item => (
              <SmallProduct 
                key={ item.product_id } 
                item={ item } 
                showPrice={ true } 
                showQty={ null } 
                onClick={ () => handleRemove(item.product_id) } 
              />
            )) }
            { (!wishlistProducts || wishlistProducts.length <= 0) && <p>Lista juaj e deshirave eshte e zbrazet.</p> }
          </div>
        </div>
      </div>
      <div className='actions'>
        <Button 
          classes='outline blue'
          title='Shto ne shporte'
          onClick={ () => dispatch(addAllToCart()) }
          icon= { 
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
              <path id="fi-rr-shopping-bag" d="M14.875,4.25H12.75a4.25,4.25,0,1,0-8.5,0H2.125A2.125,2.125,0,0,0,0,6.375v7.083A3.546,3.546,0,0,0,3.542,17h9.917A3.546,3.546,0,0,0,17,13.458V6.375A2.125,2.125,0,0,0,14.875,4.25ZM8.5,1.417A2.833,2.833,0,0,1,11.333,4.25H5.667A2.833,2.833,0,0,1,8.5,1.417Zm7.083,12.042a2.125,2.125,0,0,1-2.125,2.125H3.542a2.125,2.125,0,0,1-2.125-2.125V6.375a.708.708,0,0,1,.708-.708H4.25V7.083a.708.708,0,0,0,1.417,0V5.667h5.667V7.083a.708.708,0,0,0,1.417,0V5.667h2.125a.708.708,0,0,1,.708.708Z" transform="translate(0 0)" fill="#26a9e0"/>
            </svg>
          }
        />

        <Link to='/wishlist' className='outline green'>
          <svg xmlns="http://www.w3.org/2000/svg" width="19.06" height="16.747" viewBox="0 0 19.06 16.747">
            <path id="fi-rr-heart" d="M13.892,1.917a5.081,5.081,0,0,0-4.366,2.62,5.081,5.081,0,0,0-4.366-2.62A5.4,5.4,0,0,0,0,7.514c0,3.61,3.8,7.552,6.986,10.225a3.949,3.949,0,0,0,5.081,0c3.187-2.673,6.986-6.615,6.986-10.225a5.4,5.4,0,0,0-5.16-5.6ZM11.046,16.524a2.36,2.36,0,0,1-3.04,0C3.926,13.1,1.587,9.818,1.587,7.514A3.811,3.811,0,0,1,5.159,3.5a4.094,4.094,0,0,1,3.572,2.89.794.794,0,0,0,1.588,0A4.146,4.146,0,0,1,13.892,3.5a3.811,3.811,0,0,1,3.572,4.009c0,2.3-2.34,5.588-6.418,9.007Z" transform="translate(0.005 -1.917)" fill="#0e9548"/>
          </svg>
          Shiko listen e deshirave
        </Link>
      </div>
    </>
  )
}