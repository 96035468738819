export const sorting = (type) => {
  switch (type) {
    case "lowest":
      return { sort_by: "price", sort_order: "asc" };
    case "highest":
      return { sort_by: "price", sort_order: "desc" };
    case "popularity":
      return { sort_by: "popularity", sort_order: "desc" };
    case "newest":
      return { sort_by: "timestamp", sort_order: "desc" };
    case "lowest-by-discount":
      return { sort_by: "price", sort_order: "desc" };
    default:
      return { sort_by: "avail_since", sort_order: "desc" };
  }
};
