import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllRoutes from "./routes/index";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import MessengerCustomerChat from "react-messenger-customer-chat";

function App() {
  const location = useLocation();
  const { alert } = useSelector((state) => state.custom);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (alert?.msg) toast(alert?.msg, { type: alert.type });
  }, [alert]);

  return (
    <>
      <Header />
      <div className="main-container">
        <AllRoutes />
        <ToastContainer
          position="bottom-right"
          autoClose={4000}
          pauseOnHover
          draggable
          closeOnClick
          limit={3}
        />
        <MessengerCustomerChat
          page_id="700457200335248"
          appId="304490795513184" 
        />
      </div>
      <Footer />
    </>
  );
}

export default App;
