import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../redux/app/profile/actions';
import PasswordInput from '../PasswordInput';

export default function ChangePassword() {
  const dispatch = useDispatch();
  const { isSubmitting } = useSelector(state => state.profile);

  const ProfileSchema = Yup.object().shape({
    password: Yup.string().required(),
    newPassword: Yup.string()
      .required("Please enter your new password")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirmPassword: Yup.string()
      .required("Please confirm your password")
      .when("newPassword", {
        is: newPassword => (newPassword && newPassword.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("newPassword")], "Password doesn't match")
      })
  });

  return (
    <Formik
      initialValues={{ 
        password: '',
        newPassword: '',
        confirmPassword: ''
      }}
      validationSchema={ ProfileSchema }
      onSubmit={values => {
        dispatch(changePassword(values));
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <div className='form'>
            <div className='form-row'>
              <div className='position-relative form-group'>
                <label htmlFor='password'>Fjalekalimi*</label>
                <PasswordInput name="password" errors={ errors } touched={ touched } />
              </div>
            </div>

            <div className='form-row'>
              <div className='position-relative form-group'>
                <label htmlFor='newPassword'>Fjalekalimi i ri*</label>
                <PasswordInput name="newPassword" errors={ errors } touched={ touched } />
              </div>
            </div>

            <div className='form-row'>
              <div className='position-relative form-group'>
                <label htmlFor='confirmPassword'>Konfirmo fjalekalimin*</label>
                <PasswordInput name="confirmPassword" errors={ errors } touched={ touched } />
              </div>
            </div>

            <div className='d-flex justify-center'>
              <Button 
                type='submit'
                classes='green continue'
                title='Ruaj te dhenat'
                loading={ isSubmitting }
              />
            </div>
            
          </div>
        </Form>
      )}
    </Formik>
  )
}