import React, { useEffect } from 'react'
import Button from '../Button'
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { checkReset, resetPassword } from "../../redux/app/reset/actions";
import Loader from "../Loader";
import PasswordInput from '../PasswordInput';

export default function ResetNew() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isLoading, isOk, isSubmitting, isSuccess } = useSelector((state) => state.reset);
  
  const ResetValidation = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confPassword: Yup.string().required("Confirm password is required").oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  useEffect(() => {
    dispatch(checkReset(params.key))
  }, [params.key]);

  useEffect(() => {
    if(isOk === false && !isLoading) navigate('/');
  }, [isLoading, isOk])

  useEffect(() => {
    if(isSuccess === true && !isSubmitting) navigate('/login');
  }, [isSuccess, isSubmitting])

  const handleSubmit = (values) => {
    dispatch(resetPassword(user, params.key, values));
  };

  return (
    isLoading || !isOk ? <Loader /> : (
      <div className='account-forms'>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={ResetValidation}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <h4 className='title'>Resetoni fjalekalimin</h4>
              
              <div className='form-group'>
                <label htmlFor='password'>Fjalekalimi*</label>
                <PasswordInput name="password" errors={ errors } touched={ touched } />
              </div>
              
              <div className='form-group'>
                <label htmlFor='confPassword'>Perserit fjalekalimin*</label>
                <PasswordInput name="confPassword" errors={ errors } touched={ touched } />
              </div>
              
              <div className='d-flex flex-column align-center'>
                <Button type='submit' title='Dergo' classes='green submit' loading={ isSubmitting } />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  )
}