import { put, takeLatest } from "redux-saga/effects";
import { setShippings } from "./actions";
import { GET_SHIPPINGS } from "./types";
import api from "../../../utils/axios";

// watcher
export function* shippingsInputWatcher() {
    yield takeLatest(GET_SHIPPINGS, INIT);
}

// worker
export function* INIT() {
    try {
        const { shippings } = (yield api.get(`/shippings`)).data;
        console.log(shippings);
        yield put(setShippings(shippings));
    } catch (e) {
        console.log(e);
    }
}
