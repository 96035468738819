import React, { useState, useEffect, useRef } from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import Button from '../components/Button'
import PreferedModels from '../components/Home/PreferedModels'
import { getOrders } from '../redux/app/orders/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader'
import { useMediaQuery } from 'react-responsive';
import { useReactToPrint } from 'react-to-print';
import Invoice from '../components/Orders/Invoice';
import { savePDF } from '@progress/kendo-react-pdf';

export default function Orders() {
  const dispatch = useDispatch();
  const [order, setOrder] = useState(null);
  const { orders, isLoading } = useSelector(state => state.orders)
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch])

  const status = (status) => {
    switch (status){
      case 'P': return 'Processed';
      case 'C': return 'Complete';
      case 'O': return 'Open';
      case 'F': return 'Failed';
      case 'D': return 'Declined';
      case 'B': return 'Backordered';
      case 'I': return 'Cancelled';
      case 'Y': return 'Awaiting call';
      default: return 'Unknown';
    }
  }

  const componentRef = useRef();
  const handleDownload = () => {
    savePDF(componentRef.current, { 
      title: `Order ${order.order_id}`, 
      fileName: `Order ${order.order_id}`, 
      paperSize:  "A4"
    }, () => setOrder(null));
  }

  useEffect(() => {
    if(order) handleDownload();
  }, [order])

  return ( 
    <>
      <Breadcrumbs active_page="Historia e porosive" crumbs={ [] } />
      { isLoading ? <Loader /> : (
        <div className='container cart-container orders'>
          <h3 className='page-title'>Historia e porosive</h3>
          <div className='cart-table-container'>
            <table className='cart'>
              <thead>
                <tr>
                  <th>ID e porosise</th>
                  { isMobile && <th className='mobile-header'>Porosia</th> }
                  <th>Statusi</th>
                  <th>Lloji i pageses</th>
                  <th>Totali</th>
                  <th>Data</th>
                  <th>Shkarko faturen</th>
                </tr>
              </thead>

              <tbody>
                { orders.length > 0 && orders.map(item => (
                  <tr key={ item.order_id }>
                    <td><span>ID</span>#{ item.order_id }</td>
                    <td><span>Statusi</span>{ status(item.status) }</td>
                    <td><span>Lloji i pageses</span>{ item.payment_method.payment }</td>
                    <td className='price'><span>Totali</span>{ item.total }&euro;</td>
                    <td><span>Data</span>{ new Date(item.timestamp * 1000).toLocaleString() }</td>
                    <td className='mobile-border btn-center'>
                      <Button 
                        classes='outline green'
                        onClick={ () => setOrder(item) }
                        loading={ order?.order_id === item.order_id }
                        title='Shkarko faturen'
                        icon={
                          <svg id="fi-rr-upload" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <path id="Path_666" data-name="Path 666" d="M9.473,1.724l0,10.327a.669.669,0,0,0,.669.669h0a.669.669,0,0,0,.669-.669l0-10.316,1.948,1.949a.669.669,0,0,0,.946,0h0a.669.669,0,0,0,0-.946L11.557.588a2.007,2.007,0,0,0-2.839,0L6.57,2.737a.669.669,0,0,0,0,.946h0a.669.669,0,0,0,.946,0Z" transform="translate(-2.138 0)" fill="#0e9548"/>
                            <path id="Path_667" data-name="Path 667" d="M14.667,16.669v2.676a.668.668,0,0,1-.667.669H2a.668.668,0,0,1-.667-.669V16.669A.668.668,0,0,0,.667,16h0A.668.668,0,0,0,0,16.669v2.676a2,2,0,0,0,2,2.007H14a2,2,0,0,0,2-2.007V16.669A.668.668,0,0,0,15.333,16h0A.668.668,0,0,0,14.667,16.669Z" transform="translate(0 -5.352)" fill="#0e9548"/>
                          </svg>
                        }
                      />
                    </td>
                  </tr>
                ))}
                { orders.length <= 0 && <tr><td colSpan="6">Nuk keni porosi.</td></tr>}
              </tbody>
            </table>

            <div style={{ height: 0, overflow: 'hidden' }}>
              { order && (<Invoice reff={componentRef} order={ order } />)}
            </div>
          </div>
        </div>
      )}
      <PreferedModels rows={ 1 } heading='Trendet e fundit' />
    </>
  )
}