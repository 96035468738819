/********************
 ACTIONS
 ********************/
 import { 
  CHANGE_PASSWORD,
  UPDATE_SUBMITTING,
  GET_USER,
  SET_USER,
  CLEAR_SUCCESS,
  UPDATE_USER,
  REQUEST_RESET_PASSWORD
} from './types'

export const changePassword = (data) => ({
  type: CHANGE_PASSWORD,
  data
});

export const updateSubmitting = (status = false, success = false) => ({
  type: UPDATE_SUBMITTING,
  status,
  success
});

export const getUser = () => ({
  type: GET_USER
});

export const setUser = (user) => ({
  type: SET_USER,
  user
});

export const updateUser = (user, _user) => ({
  type: UPDATE_USER,
  user,
  _user
});

export const requestResetPassword = (email) => ({
  type: REQUEST_RESET_PASSWORD,
  email
});

export const clearSuccess = () => ({
  type: CLEAR_SUCCESS
});