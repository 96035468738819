import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import Home from "../pages/Home";
import Login from "../pages/Login";
import Register from "../pages/Register";

import Reset from "../pages/Reset";
import ResetIndex from "../components/Reset/ResetIndex";
import ResetNew from "../components/Reset/ResetNew";

import SingleProduct from "../pages/SingleProduct";

import Cart from "../pages/Cart";
import CartIndex from "../components/Cart/CartIndex";
import CartBuy from "../components/Cart/CartBuy";
import CartSuccess from "../components/Cart/CartSuccess";

import Profile from "../pages/Profile";
import Orders from "../pages/Orders";

import Wishlist from "../pages/Wishlist";

import Search from "../pages/Search";
import Compare from "../pages/Compare";

import Terms from "../pages/Terms";
import Privacy from "../pages/Privacy";
import Faqs from "../pages/Faqs";
import Transport from "../pages/Transport";
import DellWarranty from "../pages/DellWarranty";
import WarrantyServis from "../pages/WarrantyServis";
import Returns from "../pages/Returns";
import AboutOrder from "../pages/AboutOrder";
import Career from "../pages/Career";
import CostumerCare from "../pages/CostumerCare";

import NotFound from "../pages/404";
import ChangePassword from "../components/Profile/ChangePassword";
import UpdateProfile from "../components/Profile/UpdateProfile";
import PcBuilder from "../pages/PcBuilder";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const PublicRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return isAuthenticated ? <Navigate to="/profile" /> : children;
};

function AllRoutes() {
  return (
    <Routes>
      <Route path="/" element={ <Home /> } />
      <Route path="/login" element={ <Login /> } />
      <Route path="/register" element={ <PublicRoute><Register /></PublicRoute> } />
      <Route path="/reset" element={ <PublicRoute><Reset /></PublicRoute> }>
        <Route index element={<ResetIndex />} />
        <Route path="new/:key" element={<ResetNew />} />
      </Route>
      <Route path="/product/:productId" element={ <SingleProduct /> } />
      <Route path="/cart" element={ <Cart /> }>
        <Route index element={<CartIndex />} />
        <Route path="buy" element={<CartBuy />} />
        <Route path="success" element={<CartSuccess />} />
      </Route>
      <Route path="/profile" element={ <PrivateRoute><Profile /></PrivateRoute> } >
        <Route index element={<UpdateProfile />} />
        <Route path="changepassword" element={<ChangePassword />} />
      </Route>
      <Route path="/orders" element={ <PrivateRoute><Orders /></PrivateRoute> } />
      <Route path="/wishlist" element={ <Wishlist /> } />
      <Route path="/search" element={ <Search /> } />
      <Route path="/compare" element={ <Compare /> } />
      <Route path="/page/:id" element={ <Terms /> } />
      <Route path="/privacy" element={ <Privacy /> } />
      <Route path="/faqs" element={ <Faqs /> } />
      <Route path="/transport" element={ <Transport /> } />
      <Route path="/dellwarranty" element={ <DellWarranty /> } />
      <Route path="/warranty-servis" element={ <WarrantyServis /> } />
      <Route path="/returns" element={ <Returns /> } />
      <Route path="/about-order" element={ <AboutOrder /> } />
      <Route path="/career" element={ <Career /> } />
      <Route path="/costumer-care" element={ <CostumerCare /> } />
      <Route path="*" element={ <NotFound /> } />
      <Route path="/pc-builder" element={<PcBuilder/>}></Route>
    </Routes>
  );
}

export default AllRoutes;