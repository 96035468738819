import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { SmallProduct } from "./Product";
import Side from "./Cart/Side";
import Button from "./Button";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { getSearchProducts } from "../redux/app/search-input/actions";
import { logout } from "../redux/app/auth/actions";
import { clearCart } from "../redux/app/cart/actions";
import MobileMenu from "./MobileMenu";
import qs from "query-string";

function Header() {
  const navigate = useNavigate();
  const ref = useRef();
  const [showSearch, setSearch] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [isVisible, setVisibility] = useState(null);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { length } = useSelector((state) => state.cart);
  const { length: wishlistLength } = useSelector((state) => state.wishlist);
  const { categories } = useSelector((state) => state.categories);
  const { products, isLoading } = useSelector((state) => state.searchInput);
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const { c, q } = qs.parse(search);
  const [activePage, setActivePage] = useState(null);

  useEffect(() => {
    active();
  }, [c, categories]);

  useEffect(() => {
    if (!q && ref.current.value) ref.current.value = "";
  }, [q]);

  useEffect(() => {
    if (isVisible) setVisibility(false);
    if (mobileMenu) setMobileMenu(false);
  }, [pathname]);

  const handleChange = (event) => {
    const val = event.target.value;
    if (val.length >= 2) {
      dispatch(getSearchProducts(val));
      setSearch(true);
    } else setSearch(false);
  };

  const handleKeyDown = (event) => {
    const keyword = event.target.value;
    if (keyword.length >= 2 && event.keyCode === 13) {
      navigate(`/search?q=${keyword}`);
      setSearch(false);
    }
  };

  const handleFocus = (event) => {
    const val = event.target.value;
    if (val.length > 2) setSearch(true);
  };

  const handleBlur = () => {
    setTimeout(() => setSearch(false), 100);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearCart());
    localStorage.removeItem("cart");
  };

  const active = () => {
    if (!c || !categories) {
      setActivePage(null);
      return;
    }
    for (const [key, value] of categories.entries()) {
      if (value.category_id === c) {
        return setActivePage(c);
      }
      if (value.subs.length > 0) {
        for (let ctg of value.subs) {
          if (ctg.category_id === c) {
            return setActivePage(value.category_id);
          }
        }
      }
    }
  };

  return (
    <>
      <header>
        <div className="top-header" style={{ backgroundColor: "black" }}>
          <div className="container">
            <div className="d-flex align-center logo-container">
              <Link to="/">
                <img src="/assets/images/logo.svg" alt="Buton logo" />
              </Link>
            </div>

            <div className="search-container">
              <img
                className="icon"
                src="/assets/images/search.svg"
                alt="Search icon"
              />
              <input
                type="text"
                ref={ref}
                placeholder="Search"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
              />

              {showSearch && (
                <div
                  className={`search-results small-products ${
                    isLoading && "loader"
                  }`}
                >
                  <div className="search-scroll">
                    {products.length > 0 &&
                      products.map((item) => (
                        <SmallProduct key={item.product_id} item={item} />
                      ))}
                    {products.length <= 0 && <p>No data found.</p>}
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex align-center actions">
              <Button
                onClick={() => setVisibility("shopping")}
                icon={
                  <div className="icon-container price-badge-container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.069"
                      height="20.069"
                      viewBox="0 0 20.069 20.069"
                    >
                      <path
                        id="fi-rr-shopping-bag"
                        d="M17.56,5.017H15.052a5.017,5.017,0,1,0-10.034,0H2.509A2.509,2.509,0,0,0,0,7.526v8.362a4.186,4.186,0,0,0,4.181,4.181H15.888a4.186,4.186,0,0,0,4.181-4.181V7.526A2.509,2.509,0,0,0,17.56,5.017ZM10.034,1.672a3.345,3.345,0,0,1,3.345,3.345H6.69a3.345,3.345,0,0,1,3.345-3.345ZM18.4,15.888A2.509,2.509,0,0,1,15.888,18.4H4.181a2.509,2.509,0,0,1-2.509-2.509V7.526a.836.836,0,0,1,.836-.836H5.017V8.362a.836.836,0,1,0,1.672,0V6.69h6.69V8.362a.836.836,0,1,0,1.672,0V6.69H17.56a.836.836,0,0,1,.836.836Z"
                        transform="translate(0 0)"
                        fill="#fff"
                      />
                    </svg>
                    {length > 0 && (
                      <span className="price-badge">{length}</span>
                    )}
                  </div>
                }
              />

              <Button
                onClick={() => setVisibility("wishlist")}
                icon={
                  <div className="icon-container price-badge-container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.841"
                      height="20.069"
                      viewBox="0 0 22.841 20.069"
                    >
                      <path
                        id="fi-rr-heart"
                        d="M16.648,1.917a6.088,6.088,0,0,0-5.232,3.139A6.088,6.088,0,0,0,6.183,1.917,6.469,6.469,0,0,0,0,8.624c0,4.326,4.553,9.05,8.372,12.253a4.732,4.732,0,0,0,6.088,0c3.819-3.2,8.372-7.927,8.372-12.253a6.469,6.469,0,0,0-6.184-6.707Zm-3.41,17.5a2.828,2.828,0,0,1-3.644,0C4.706,15.32,1.9,11.385,1.9,8.624a4.566,4.566,0,0,1,4.281-4.8,4.906,4.906,0,0,1,4.281,3.463.951.951,0,0,0,1.9,0A4.968,4.968,0,0,1,16.648,3.82a4.566,4.566,0,0,1,4.281,4.8c0,2.762-2.8,6.7-7.691,10.794Z"
                        transform="translate(0.005 -1.917)"
                        fill="#fff"
                      />
                    </svg>
                    {wishlistLength > 0 && (
                      <span className="price-badge">{wishlistLength}</span>
                    )}
                  </div>
                }
              />

              {isMobile ? (
                <Button
                  onClick={() => setMobileMenu(true)}
                  icon={
                    <svg
                      id="fi-rr-align-right"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.293"
                      height="13.666"
                      viewBox="0 0 19.293 13.666"
                    >
                      <path
                        id="Path_336"
                        data-name="Path 336"
                        d="M.8,5.608H18.49A.8.8,0,0,0,18.49,4H.8a.8.8,0,0,0,0,1.608Z"
                        transform="translate(0 -4)"
                        fill="#fff"
                      />
                      <path
                        id="Path_337"
                        data-name="Path 337"
                        d="M20.058,9H8.8a.8.8,0,0,0,0,1.608H20.058a.8.8,0,0,0,0-1.608Z"
                        transform="translate(-1.569 -4.981)"
                        fill="#fff"
                      />
                      <path
                        id="Path_338"
                        data-name="Path 338"
                        d="M20.058,19H8.8a.8.8,0,0,0,0,1.608H20.058a.8.8,0,0,0,0-1.608Z"
                        transform="translate(-1.569 -6.942)"
                        fill="#fff"
                      />
                      <path
                        id="Path_339"
                        data-name="Path 339"
                        d="M18.49,14H.8a.8.8,0,0,0,0,1.608H18.49a.8.8,0,0,0,0-1.608Z"
                        transform="translate(0 -5.961)"
                        fill="#fff"
                      />
                    </svg>
                  }
                />
              ) : isAuthenticated ? (
                <div className="logged-in dropdown">
                  <div className="icon-container">
                    <svg
                      id="fi-rr-user"
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.052"
                      height="20.069"
                      viewBox="0 0 15.052 20.069"
                    >
                      <path
                        id="Path_672"
                        data-name="Path 672"
                        d="M11.017,10.034A5.017,5.017,0,1,0,6,5.017a5.017,5.017,0,0,0,5.017,5.017Zm0-8.362A3.345,3.345,0,1,1,7.672,5.017,3.345,3.345,0,0,1,11.017,1.672Z"
                        transform="translate(-3.491 0)"
                        fill="#fff"
                      />
                      <path
                        id="Path_673"
                        data-name="Path 673"
                        d="M10.526,14A7.534,7.534,0,0,0,3,21.526a.836.836,0,1,0,1.672,0,5.853,5.853,0,0,1,11.707,0,.836.836,0,1,0,1.672,0A7.534,7.534,0,0,0,10.526,14Z"
                        transform="translate(-3 -2.293)"
                        fill="#fff"
                      />
                    </svg>
                    <div className="dropdown-items">
                      <Link to="/profile">Profili</Link>
                      <Link to="/orders">Historia e porosive</Link>
                      <Link to="" onClick={handleLogout}>
                        Ç’kyqu
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <NavLink to="/login">
                  <div className="icon-container">
                    <svg
                      id="fi-rr-user"
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.052"
                      height="20.069"
                      viewBox="0 0 15.052 20.069"
                    >
                      <path
                        id="Path_672"
                        data-name="Path 672"
                        d="M11.017,10.034A5.017,5.017,0,1,0,6,5.017a5.017,5.017,0,0,0,5.017,5.017Zm0-8.362A3.345,3.345,0,1,1,7.672,5.017,3.345,3.345,0,0,1,11.017,1.672Z"
                        transform="translate(-3.491 0)"
                        fill="#fff"
                      />
                      <path
                        id="Path_673"
                        data-name="Path 673"
                        d="M10.526,14A7.534,7.534,0,0,0,3,21.526a.836.836,0,1,0,1.672,0,5.853,5.853,0,0,1,11.707,0,.836.836,0,1,0,1.672,0A7.534,7.534,0,0,0,10.526,14Z"
                        transform="translate(-3 -2.293)"
                        fill="#fff"
                      />
                    </svg>
                  </div>
                </NavLink>
              )}
            </div>
          </div>
        </div>

        {!isMobile && (
          <nav className="menu">
            <ul className={`container ${!categories && "loader"} `}>
              {categories &&
                [...categories.keys()].map((item) => {
                  const category = categories.get(item);
                  return (
                    <li className="dropdown" key={category.category_id}>
                      <Link
                        className={activePage === item ? "active" : null}
                        to={`/search?c=${category.category_id}`}
                      >
                        {category.category}
                      </Link>
                      {category.subs.length > 0 && (
                        <div className="dropdown-items">
                          {category.subs.map((item) => (
                            <Link
                              to={`/search?c=${item.category_id}`}
                              key={item.category_id}
                            >
                              <img
                                src={item?.main_pair?.detailed?.image_path}
                                alt={item.category}
                              />
                              <span style={{ display: "block", width: "100%" }}>
                                {item.category}
                              </span>
                            </Link>
                          ))}
                        </div>
                      )}
                    </li>
                  );
                })}
      
            </ul>
          </nav>
        )}
        {isMobile && (
          <div className="search-container mobile-search">
            <img
              className="icon"
              src="/assets/images/search-black.svg"
              alt="Search icon"
            />
            <input
              type="text"
              placeholder="Search"
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
            />

            {showSearch && (
              <div className={`search-results small-products`}>
                <div className="search-scroll">
                  {products.length > 0 &&
                    products.map((item) => (
                      <SmallProduct key={item.id} item={item} />
                    ))}
                  {products.length <= 0 && <p>No data found.</p>}
                </div>
              </div>
            )}
          </div>
        )}
      </header>
      <Side isVisible={isVisible} setVisibility={setVisibility} />
      {isMobile && (
        <MobileMenu mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
      )}
    </>
  );
}

export default Header;
