import { put, takeEvery } from "redux-saga/effects";
import { setProducts } from "./actions";
import api from "../../../../utils/axios";

import { GET_TRENDS_PRODUCTS } from "./types";

// watcher
export function* trendsWatcher() {
  yield takeEvery(GET_TRENDS_PRODUCTS, INIT);
}

// worker
export function* INIT({ data, parent }) {
  try {
    const response = (yield api.get("/products", {
      params: {
        status: "A",
        sort_by: "popularity",
        sort_order: "desc",
        amount_from: 0,
        ...data,
      },
    })).data;

    yield put(setProducts(response.products, parent));
  } catch (e) {
    console.log(e);
  }
}
